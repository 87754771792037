// aws tools
import { Auth, API, graphqlOperation } from "aws-amplify";
import * as queries from "./queries";
import * as mutations from "./mutations";
import * as subscriptions from "./subscriptions";

// date handling tools
import dayjs from "dayjs";

// cookie handling tools
import Cookies from "js-cookie";

// entzy config
import configEntzy from "components/config/ConfigEntzy";

// service backend api calls
export const serviceGraphCall = async (type, name, payload, callback) => {
  let action, call, subscribe;
  try {
    switch (type) {
      case "mutation":
        action = mutations[name];
        break;
      case "subscription":
        action = subscriptions[name];
        subscribe = true;
        break;
      default:
        action = queries[name];
        break;
    }
    // console.log("Calling service with " + type + ": " + name);
    // console.log("Call payload: ", payload);
    if (subscribe) {
      call = await API.graphql(graphqlOperation(action, payload)).subscribe({
        next: (response) => {
          // console.log("SUBS FIRE! Subscription response: ", response);
          // console.log(response.value.data[name]);
          if (callback) {
            callback.call(null, callback.observable, response.value.data[name]);
          }
        },
        error: (error) => {
          console.error("subscription callback error");
          // console.error(error);
          if (callback) {
            callback.call(error);
          }
        },
      });
      return {
        success: true,
        data: call,
      };
    } else {
      call = await API.graphql(graphqlOperation(action, payload));
      // console.log("Call response: ", call);
      return {
        success: true,
        data: call.data[name],
      };
    }
  } catch (error) {
    const catchMessage =
      "Action could not be processed. Double check and give it another go. Contact us if this problem persists.";
    const message = error.errors
      ? error.errors[0].message
      : error.message
      ? error.message
      : catchMessage;
    return {
      success: false,
      data: error,
      message: message.startsWith("EMSG:")
        ? message.replace("EMSG:", "")
        : catchMessage,
    };
  }
};

// sync user checks after login
export const serviceSyncUser = async (action) => {
  try {
    const userAuth = await Auth.currentAuthenticatedUser();
    const userInfo = await Auth.currentUserInfo();
    const response = await serviceGraphCall("mutation", "syncUser", {
      Action: action,
    });
    const userData = response.data;
    const name = userData.Name;
    const paymentid = userData.PaymentId;
    const payoutid = userData.PayoutId;
    const paymentchk = paymentid ? ":" + paymentid + ":" : ":none:none:none:";
    const payoutchk = payoutid ? ":" + payoutid + ":" : ":none:none:none:";
    // client side user sanity checks
    // note: cognito requires booleans as string
    // resync cognito name (should only be required on signup)
    if (name && !userInfo.attributes["name"]) {
      Auth.updateUserAttributes(userAuth, { name });
    }
    if (
      !paymentchk.includes(":none:") &&
      !userInfo.attributes["custom:entzy_payment_set"]
    ) {
      Auth.updateUserAttributes(userAuth, {
        "custom:entzy_payment_set": "true",
      });
    }
    if (
      paymentchk.includes(":none:none:") &&
      userInfo.attributes["custom:entzy_payment_set"]
    ) {
      Auth.updateUserAttributes(userAuth, {
        "custom:entzy_payment_set": "false",
      });
    }
    if (
      !payoutchk.includes(":none:") &&
      !userInfo.attributes["custom:entzy_payout_set"]
    ) {
      Auth.updateUserAttributes(userAuth, {
        "custom:entzy_payout_set": "true",
      });
    }
    if (
      payoutchk.includes(":none:none:none:") &&
      userInfo.attributes["custom:entzy_payout_set"]
    ) {
      Auth.updateUserAttributes(userAuth, {
        "custom:entzy_payout_set": "false",
      });
    }
  } catch (error) {
    console.error(
      "Unable to sync user settings. Either not logged in or other issue. Try a reconnect to resolve."
    );
    serviceLogError("serviceSyncUser", error);
  }
};

// sync device settings after login
export const serviceSyncDevice = async (user, data) => {
  const serviceAction = "serviceSyncDevice";
  const results = {
    serviceAction,
    success: false,
  };
  try {
    const userAuth = await Auth.currentAuthenticatedUser();
    const userLastDeviceSynced = user.status.device_synced;
    if (data.platform && data.token) {
      const syncDateStamp = dayjs().format("YYMMDD");
      const deviceStamp = configEntzy.DEVICE_SYNC_STAMP.generateStamp({
        platform: data.platform,
        token: data.token,
        date: syncDateStamp,
      });
      // parse last sync data and check if this device has already synced today
      const devicesSyncedList = userLastDeviceSynced.split(
        configEntzy.DEVICE_SYNC_STAMP.DELIMITERS[1]
      );
      const newStamp = deviceStamp.split(
        configEntzy.DEVICE_SYNC_STAMP.DELIMITERS[1]
      )[0];
      results.deviceSyncedToday = false;
      for (let priorStamp of devicesSyncedList) {
        if (priorStamp === newStamp) {
          results.deviceSyncedToday = true;
          break;
        }
      }
      if (results.deviceSyncedToday) {
        results.success = true;
        results.message = "Skipped action. Device already synced today.";
        return results;
      }
      // initiate push subscription
      results.callSubscribeAction = await serviceGraphCall(
        "mutation",
        "pushSubscribe",
        {
          SubscribeUserId: user.identity,
          SubscribePlatform: data.platform,
          SubscribeToken: data.token,
        }
      );
      if (
        results.callSubscribeAction.success &&
        results.callSubscribeAction.data === "complete"
      ) {
        // track last syncs up to 5 devices (100 chars max) in user profile with
        // fixed width trackers of 20 that will rotate in order of newest stamp
        results.updatedDeviceSynced =
          deviceStamp + userLastDeviceSynced.replace(deviceStamp, "");
        results.updatedDeviceSynced = results.updatedDeviceSynced.slice(
          0,
          configEntzy.DEVICE_SYNC_STAMP.MAX_CONCAT_LENGTH
        );
        results.callUserAction = await Auth.updateUserAttributes(userAuth, {
          "custom:entzy_device_synced": results.updatedDeviceSynced,
        });
        results.success = true;
      } else {
        results.success = false;
        results.alert = true;
        results.message =
          "Unable to sync device push notifications. Try a reconnect to resolve.";
        serviceLogError(serviceAction, results);
      }
    } else {
      results.success = false;
      results.alert = false;
      results.message = "Invalid input data: mandatory fields missing";
      serviceLogError(serviceAction, results);
    }
  } catch (error) {
    results.success = false;
    results.alert = false;
    results.message =
      "Invalid state: unable to sync device or user not connected.";
    serviceLogError(serviceAction, results);
  }
  return results;
};

export const serviceUserSetting = async (params) => {
  let response, actionType, actionMethod;
  if (params.delete) {
    actionType = "mutation";
    actionMethod = "deleteUserSetting";
  } else if (params.create) {
    actionType = "mutation";
    actionMethod = "createUserSetting";
  } else if (params.update) {
    actionType = "mutation";
    actionMethod = "createUserSetting";
  } else if (params.list) {
    actionType = "query";
    actionMethod = "getUserSettingList";
  } else {
    actionType = "query";
    actionMethod = "getUserSetting";
  }
  if (params.admin) {
    actionMethod = actionMethod.replace("User", "UserAdmin");
  }
  response = await serviceGraphCall(actionType, actionMethod, {
    SettingId: params.SettingId,
    SettingUserId: params.SettingUserId,
    SettingValue: params.SettingValue
      ? params.SettingValue
      : params.create
      ? "active"
      : null,
    // list settings
    HashCategory1: params.HashCategory1,
    HashCategory2: params.HashCategory2,
    HashCategory3: params.HashCategory3,
    HashCategory4: params.HashCategory4,
    RangeCategory1: params.RangeCategory1,
    RangeCategory2: params.RangeCategory2,
    RangeCategory3: params.RangeCategory3,
    RangeCategory4: params.RangeCategory4,
    limit: params.limit,
    nextToken: params.nextToken,
  });
  if (!response.success) {
    response.alert = true;
    return response;
  }
  return response;
};

export const serviceJoinInvite = async (user, data) => {
  const serviceAction = "serviceJoinInvite";
  const results = {
    user,
    serviceAction,
    success: false,
  };
  let response;
  response = await serviceGraphCall("mutation", "acceptJoinLink", {
    EventId: data.eid,
    InviteCode: data.token,
  });
  if (!response.success) {
    results.success = false;
    results.alert = true;
    results.message = response.message;
    return results;
  }
  results.autoJoinEvent = {
    EventId: data.eid,
    Url: response.data,
    PointerUrl: configEntzy.URL_POINTERS.MAIN + response.data,
    pin: true,
    owner: false,
    manager: data.token.split(":")[1] === "runner" ? true : false,
  };
  response = await serviceGraphCall("mutation", "joinEventEntry", {
    EventId: data.eid,
  });
  if (!response.success) {
    results.success = false;
    results.alert = true;
    results.message = response.message;
    return results;
  }
  results.success = true;
  results.alert = true;
  results.message =
    "Invite accepted. Eventuator " +
    results.autoJoinEvent.PointerUrl +
    " has been pinned to your feed.";
  response = await serviceGraphCall("query", "publicViewEvent", {
    Url: results.autoJoinEvent.Url,
  });
  if (!response.success) {
    results.success = false;
    results.alert = true;
    results.message = response.message;
    return results;
  }
  results.autoJoinEvent.data = response.data;
  return results;
};

export const servicePaymentConfirm = async (user, data) => {
  const serviceAction = "servicePaymentConfirm";
  const results = {
    user,
    serviceAction,
    success: false,
  };
  const clientSecretUrl = data.clientSecret;
  const clientSecretCookie = serviceCookieGet(
    configEntzy.STRIPE_PAYMENT_INTENT_PARAMS.cookie_secret_key
  );
  if (
    clientSecretCookie &&
    clientSecretUrl &&
    clientSecretCookie === clientSecretUrl
  ) {
    let response;
    const { paymentIntent } = await data.stripe.retrievePaymentIntent(
      clientSecretCookie
    );
    if (paymentIntent.status !== "succeeded") {
      results.alert = true;
      results.message = paymentIntent.last_setup_error
        ? paymentIntent.last_setup_error.message
        : "Unknown error";
      results.message =
        "Payment could not be validated: " +
        results.message +
        " Make any necessary changes and give it another go.";
      return results;
    }
    response = await serviceGraphCall("query", "publicGetPaymentIntent", {
      Action: "confirm",
      PaymentIntentId: paymentIntent.id,
      Email: paymentIntent.receipt_email,
      Currency: paymentIntent.currency,
      Amount: paymentIntent.amount,
      Description: paymentIntent.description,
      RecipientType: "revalidate",
      RecipientName: "revalidate",
    });
    if (!response.success) {
      results.success = false;
      results.alert = true;
      results.message =
        "Unable to confirm payment. Give it another go or contact us if this problem persists.";
      return results;
    }
    // flag for any onward actions that payment has completed
    if (!data.noredirect) {
      serviceCookieSet(
        configEntzy.STRIPE_PAYMENT_INTENT_PARAMS.cookie_payment_complete,
        paymentIntent.id
      );
    }
    serviceCookieClear(
      configEntzy.STRIPE_PAYMENT_INTENT_PARAMS.cookie_secret_key
    );
    // return results
    results.success = true;
    results.alert = false;
    results.message = "Payment completed successfully.";
    // console.log("Payment setup results", results);
    return results;
  } else {
    results.success = false;
    results.alert = true;
    results.message = "Invalid input data: mandatory fields missing";
    results.error = "ERROR: Cookie url data mismatch";
    serviceLogError(serviceAction, results);
    serviceCookieClear(
      configEntzy.STRIPE_PAYMENT_INTENT_PARAMS.cookie_secret_key
    );
    return results;
  }
};

export const servicePaymentMethodSet = async (user, data) => {
  const serviceAction = "servicePaymentMethodSet";
  const results = {
    user,
    serviceAction,
    success: false,
  };
  const clientSecretUrl = data.clientSecret;
  const clientSecretCookie = serviceCookieGet(
    configEntzy.STRIPE_SETUP_INTENT_PARAMS.cookie_secret_key
  );
  if (
    clientSecretCookie &&
    clientSecretUrl &&
    clientSecretCookie === clientSecretUrl
  ) {
    let response;
    const { setupIntent } = await data.stripe.retrieveSetupIntent(
      clientSecretCookie
    );
    if (setupIntent.status !== "succeeded") {
      results.alert = true;
      results.message = setupIntent.last_setup_error
        ? setupIntent.last_setup_error.message
        : "Unknown error";
      results.message =
        "Payment method could not be validated: " +
        results.message +
        " Make any necessary changes and give it another go.";
      return results;
    }
    response = await serviceGraphCall("mutation", "updatePayment", {
      PaymentToken: setupIntent.payment_method,
    });
    if (!response.success) {
      results.success = false;
      results.alert = true;
      results.message = response.message;
      return results;
    }
    // set and update user attributes
    const userAuth = await Auth.currentAuthenticatedUser();
    await Auth.updateUserAttributes(userAuth, {
      "custom:entzy_payment_set": "true",
    });
    response = await serviceGraphCall("mutation", "syncUser", {
      Action: "profile",
    });
    if (!response.success) {
      results.success = false;
      results.alert = true;
      results.message = response.message;
      return results;
    }
    // flag for any onward actions that payment has been set
    serviceCookieSet(
      configEntzy.STRIPE_SETUP_INTENT_PARAMS.cookie_payment_set,
      "true"
    );
    serviceCookieClear(
      configEntzy.STRIPE_SETUP_INTENT_PARAMS.cookie_secret_key
    );
    // return results
    results.success = true;
    results.alert = false;
    results.message = "Payment method updated.";
    results.paymentId = response.data.PaymentId;
    // console.log("Payment setup results", results);
    return results;
  } else {
    results.success = false;
    results.alert = true;
    results.message = "Invalid input data: mandatory fields missing";
    results.error = "ERROR: Cookie url data mismatch";
    serviceLogError(serviceAction, results);
    serviceCookieClear(
      configEntzy.STRIPE_SETUP_INTENT_PARAMS.cookie_secret_key
    );
    return results;
  }
};

// capture actions client side for use when user logs in
export const serviceActionCapture = async (data) => {
  const serviceAction = "serviceActionCapture";
  const results = {
    serviceAction,
    success: false,
  };
  try {
    // create a unique action id
    let actionId = "";
    for (const [key, value] of Object.entries(data)) {
      actionId += key + ":" + value + "|";
    }
    data.id = actionId.slice(0, -1);
    // check for existing actions from cookie and filter out any matching id
    const actionList = Cookies.get("actionList")
      ? JSON.parse(Cookies.get("actionList")).filter(
          (item) => item.id !== data.id
        )
      : [];
    // push new action to list and set cookie
    actionList.push(data);
    Cookies.set("actionList", JSON.stringify(actionList), {
      domain: configEntzy.APP_COOKIE_DOMAIN,
      path: configEntzy.APP_COOKIE_PATH,
      secure: configEntzy.APP_COOKIE_SECURE,
      sameSite: configEntzy.APP_COOKIE_SAMESITE,
      expires: configEntzy.APP_COOKIE_EXPIRES_SM,
    });
    results.success = true;
  } catch (e) {
    results.success = false;
    results.alert = false;
    results.message = "Cookie error: unable to capture user action";
    results.error = e;
  }
  return results;
};
export const serviceActionListGet = () => {
  return Cookies.get("actionList") ? JSON.parse(Cookies.get("actionList")) : [];
};
export const serviceActionListClear = () => {
  Cookies.set("actionList", "[]", {
    domain: configEntzy.APP_COOKIE_DOMAIN,
    path: configEntzy.APP_COOKIE_PATH,
    secure: configEntzy.APP_COOKIE_SECURE,
    sameSite: configEntzy.APP_COOKIE_SAMESITE,
    expires: 0,
  });
  Cookies.remove("actionList", {
    domain: configEntzy.APP_COOKIE_DOMAIN,
    path: configEntzy.APP_COOKIE_PATH,
    secure: configEntzy.APP_COOKIE_SECURE,
    sameSite: configEntzy.APP_COOKIE_SAMESITE,
  });
};

// custom backdrop client side settings
const BACKDROP_DEFAULT = "backdrop drip10";
export const serviceBackdropSet = (value) => {
  const backdropValue = value
    ? BACKDROP_DEFAULT.split(" ")[0] + " drip" + value
    : BACKDROP_DEFAULT;
  Cookies.set("backdropValue", backdropValue, {
    domain: configEntzy.APP_COOKIE_DOMAIN,
    path: configEntzy.APP_COOKIE_PATH,
    secure: configEntzy.APP_COOKIE_SECURE,
    sameSite: configEntzy.APP_COOKIE_SAMESITE,
    expires: configEntzy.APP_COOKIE_EXPIRES_XL,
  });
  return backdropValue;
};
export const serviceBackdropGet = () => {
  return Cookies.get("backdropValue")
    ? Cookies.get("backdropValue")
    : BACKDROP_DEFAULT;
};
export const serviceBackdropClear = () => {
  Cookies.set("backdropValue", BACKDROP_DEFAULT, {
    domain: configEntzy.APP_COOKIE_DOMAIN,
    path: configEntzy.APP_COOKIE_PATH,
    secure: configEntzy.APP_COOKIE_SECURE,
    sameSite: configEntzy.APP_COOKIE_SAMESITE,
    expires: 0,
  });
  Cookies.remove("backdropValue", {
    domain: configEntzy.APP_COOKIE_DOMAIN,
    path: configEntzy.APP_COOKIE_PATH,
    secure: configEntzy.APP_COOKIE_SECURE,
    sameSite: configEntzy.APP_COOKIE_SAMESITE,
  });
};

// reuseable cookie handling
export const serviceCookieSet = (key, value, expires) => {
  Cookies.set(key, value, {
    domain: configEntzy.APP_COOKIE_DOMAIN,
    path: configEntzy.APP_COOKIE_PATH,
    secure: configEntzy.APP_COOKIE_SECURE,
    sameSite: configEntzy.APP_COOKIE_SAMESITE,
    expires: expires ? expires : configEntzy.APP_COOKIE_EXPIRES_XL,
  });
  return value;
};
export const serviceCookieGet = (key) => {
  return Cookies.get(key);
};
export const serviceCookieClear = (key) => {
  Cookies.set(key, "", {
    domain: configEntzy.APP_COOKIE_DOMAIN,
    path: configEntzy.APP_COOKIE_PATH,
    secure: configEntzy.APP_COOKIE_SECURE,
    sameSite: configEntzy.APP_COOKIE_SAMESITE,
    expires: 0,
  });
  Cookies.remove(key, {
    domain: configEntzy.APP_COOKIE_DOMAIN,
    path: configEntzy.APP_COOKIE_PATH,
    secure: configEntzy.APP_COOKIE_SECURE,
    sameSite: configEntzy.APP_COOKIE_SAMESITE,
  });
};

// log any client side errors
export const serviceLogError = async (action, error) => {
  if (typeof error !== "string") {
    error = JSON.stringify(error);
  }
  try {
    await serviceGraphCall("mutation", "logCapture", {
      LogLevel: "error",
      LogMessage: "client: " + action,
      LogTrace: error,
    });
  } catch (e) {
    console.warn("Warning: unable to log error");
  }
};
