// react core
import { useState, useEffect, useReducer, useCallback } from "react";
import { useLocation } from "react-router-dom";

// date and calendar handling
import dayjs from "dayjs";

// idle timer context
import { IdleTimerProvider } from "react-idle-timer";

// material theme
import mainTheme from "components/main/MainTheme";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";

// material components
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// entzy components
import configEntzy from "components/config/ConfigEntzy";
import ConnectFlow from "components/connect/ConnectFlow";
import { ActionAlert } from "components/utils/common/CommonLoaders";
import {
  serviceGraphCall,
  serviceUserSetting,
  serviceLogError,
} from "services/graphql/call";
import { serviceObjectDownload } from "services/storage/object";
import { googleConvertColorId } from "models/Tools";

// entzy top level pages
import HomeIndex from "pages/home/HomeIndex";
import AboutIndex from "pages/about/AboutIndex";
import AdminIndex from "pages/admin/AdminIndex";
import ContactIndex from "pages/contact/ContactIndex";
import ContactsIndex from "pages/contacts/ContactsIndex";
import LegalIndex from "pages/legal/LegalIndex";
import EventIndex from "pages/events/EventIndex";
import MemberIndex from "pages/members/MemberIndex";
import PayIndex from "pages/pay/PayIndex";
import SettingsIndex from "pages/settings/SettingsIndex";
import CatchNotFound from "pages/catch/CatchNotFound";

// entzy main context
import {
  MainContext,
  initialState,
  mainReducer,
  mainActions,
  mainObservables,
} from "./MainContext";
import newUser from "models/User";
import MainNotifications from "./MainNotifications";
import MainCloseBar from "./MainCloseBar";

// entzy assets
import aiAvatar from "assets/logos/entzy-main.png";

// entzy hooks
import { userUpdate } from "hooks/identity/identityState";

function MainContent(props) {
  const location = useLocation();
  const user = props.user;
  const navigate = props.navigate;
  const autoJoinEvent = props.autoJoinEvent;
  const autoJoinEventClear = props.autoJoinEventClear;

  const [hydratingNotifications, setHydratingNotifications] = useState(false);
  const [hydratingViewerCalendar, setHydratingViewerCalendar] = useState(false);
  const [hydratingViewerEvents, setHydratingViewerEvents] = useState(false);
  const [hydratingContacts, setHydratingContacts] = useState(false);
  const [pause, setPause] = useState(false);

  const [state, dispatch] = useReducer(mainReducer, initialState);

  // check for special url parts
  const locationParts = location.pathname.split("/");
  const eventParts = {
    name: null,
    module: null,
  };
  const eventModuleMap = {
    offer: "launchpad",
    messaging: "messaging",
    chat: "messaging",
    debate: "messaging",
    poll: "messaging",
    eventuate: "entry",
    launch: "entry",
    members: "members",
    runners: "members",
    riders: "members",
  };

  if (props.page === "/events/runner") {
    eventParts.name = locationParts[2];
    eventParts.module =
      locationParts.length > 3 &&
      locationParts[3] &&
      eventModuleMap[locationParts[3]]
        ? eventModuleMap[locationParts[3]]
        : null;
  }

  // idle timer actions
  const onPrompt = () => {
    // optional modal
  };
  const onIdle = () => {
    setPause(true);
  };
  const onActive = async (event) => {
    setPause(false);
    handleIdleOnActivate();
  };
  const onAction = (event) => {
    // watched events
  };

  // idle refresh reactivate
  const handleIdleOnActivate = async () => {
    let response;
    if (user.connected) {
      // refresh direct messages if a room is open
      if (state.memberMessaging.lastActiveRoomId) {
        const roomId = state.memberMessaging.lastActiveRoomId;
        const roomName = roomId.split(":").pop();
        const roomArea = roomId.replace(":" + roomName, "");
        const userSelfCount = roomArea.split(user.identity).length - 1;
        if (userSelfCount === 1) {
          response = await preparePullMemberMessages({
            RoomArea: roomArea,
            RoomName: roomName,
          });
          if (response.alert) {
            dispatch({
              type: mainActions.UPDATE_ALERT,
              alert: {
                show: false,
                message:
                  "Hmm unable to auto refresh direct messages. Try pulling down.",
              },
            });
          } else {
            dispatch({
              type: mainActions.PULL_MEMBER_MESSAGES,
              content: {
                roomId: roomId,
                data: response.data,
              },
            });
            response = await preparePullMemberChatSettings({
              RoomArea: roomArea,
              RoomName: roomName,
            });
            if (response.alert) {
              dispatch({
                type: mainActions.UPDATE_ALERT,
                alert: {
                  show: false,
                  message:
                    "Hmm unable to auto refresh chat settings. Try pulling down.",
                },
              });
            } else {
              dispatch({
                type: mainActions.PULL_MEMBER_CHAT_SETTINGS,
                settings: {
                  roomId: roomId,
                  data: response.data,
                },
              });
            }
          }
        }
      }
      // refresh notifications
      response = await preparePullNotifications({
        Category: null,
      });
      if (response.alert) {
        dispatch({
          type: mainActions.UPDATE_ALERT,
          alert: {
            show: false,
            message:
              "Hmm unable to auto refresh notifications. Try pulling down.",
          },
        });
      } else {
        dispatch({
          type: mainActions.PULL_NOTIFICATIONS,
          notifications: response,
        });
      }
      // end user connected
    }
  };

  // user related functions
  const getUserConnected = (params) => {
    return state.user && state.user.connected
      ? true
      : params && params.user && params.user.connected
      ? true
      : user && user.connected
      ? true
      : false;
  };
  const cbGetUserConnected = useCallback(getUserConnected, [state.user, user]);

  // observable related functions
  const callbackAction = async (error, observable, result) => {
    dispatch({
      type: mainActions.SET_CALLBACK_LOADER,
      callbackLoader: false,
    });
    if (error) {
      return errorAction(error, observable, result);
    } else {
      const payload = {
        type: observable.action.type,
      };
      payload[observable.action.property] = result;
      dispatch(payload);
    }
  };
  const cbCallbackAction = useCallback(callbackAction, []);
  const errorAction = async (error, observable, result) => {
    console.error("Warning from main observable: refresh for latest data");
    // serviceLogError("observableMainCallback", { error, observable, result });
  };
  const openSubscription = async (type, params) => {
    let response = {
      success: false,
      data: null,
    };
    // first check and close if not required for this module
    if (
      mainObservables[type].menus.length > 0 &&
      !mainObservables[type].menus.includes(state.menuSelected) &&
      !mainObservables[type].menus.includes(type) &&
      mainObservables[type].active
    ) {
      // console.log("subs close", type);
      closeSubscription(type);
    }
    // open if required for this module
    if (
      (mainObservables[type].menus.length === 0 ||
        mainObservables[type].menus.includes(state.menuSelected) ||
        mainObservables[type].menus.includes(type)) &&
      mainObservables[type].field &&
      mainObservables[type].action &&
      !mainObservables[type].active
    ) {
      // set subscription params
      const callParams = params
        ? params
        : {
            UserId: user.identity,
          };
      // console.log("subs open", type, callParams);
      response = await serviceGraphCall(
        "subscription",
        mainObservables[type].field,
        callParams,
        {
          observable: mainObservables[type],
          call: cbCallbackAction,
        }
      );
      // console.log("subs response", response);
      mainObservables[type].active = response.success;
      mainObservables[type].subscription = response.success
        ? response.data
        : null;
      if (!response.success) {
        return errorAction(response.message, mainObservables[type], response);
      }
    }
    return response.data;
  };
  const cbOpenSubscription = useCallback(openSubscription, [
    user.identity,
    state.menuSelected,
    cbCallbackAction,
  ]);
  const closeSubscription = async (type) => {
    if (mainObservables[type].active) {
      mainObservables[type].subscription.unsubscribe();
      mainObservables[type].active = false;
    }
    return mainObservables.event;
  };
  const cbCloseSubscription = useCallback(closeSubscription, []);

  const prepareOauthFlow = async (params) => {
    const userConnected = cbGetUserConnected(params);
    if (userConnected) {
      const mutation = params.code ? "oauthCodeExchange" : "oauthTokenRefresh";
      const payload = {
        IdentityProvider: params.provider,
        SourceUrl: configEntzy.APP_URL,
        Code: params.code,
        RefreshAction: params.refreshAction,
      };
      const response = await serviceGraphCall("mutation", mutation, payload);
      if (!response.success) {
        response.alert = true;
        return response;
      }
      return response;
    } else {
      return {
        alert: true,
        message: "Please connect to manage settings",
      };
    }
  };
  const cbPrepareOauthFlow = useCallback(prepareOauthFlow, [
    cbGetUserConnected,
  ]);

  const prepareCallGoogleApi = async (action) => {
    const calendarBaseUrl = "https://www.googleapis.com/calendar/v3/calendars/";
    // capture access token value
    // first check if google access token exists
    let accessToken, url, payload, response, message, refreshed, result;
    // check if google access token is expired and attempt to refresh
    accessToken = action.googleOverrideAccessToken
      ? action.googleOverrideAccessToken === "null"
        ? null
        : action.googleOverrideAccessToken
      : state.viewer.tokens.googleAccessToken
      ? state.viewer.tokens.googleAccessToken.value
      : null;
    if (!accessToken) {
      message = "Please connect to Google calendar";
      dispatch({
        type: mainActions.UPDATE_ALERT,
        alert: {
          show: true,
          message,
        },
      });
      return {
        name: action.calendar.name,
        events: [],
      };
    }
    if (
      state.viewer.tokens.googleTokenExpires &&
      state.viewer.tokens.googleTokenExpires.value &&
      state.viewer.tokens.googleTokenExpires.value.diff(dayjs(), "minutes") <= 0
    ) {
      response = await cbPrepareOauthFlow({
        provider: "google",
      });
      if (response.success) {
        try {
          const data = JSON.parse(response.data);
          if (!data.error) {
            accessToken = data.access_token;
            dispatch({
              type: mainActions.SET_TOKEN,
              token: {
                name: "googleTokenExpires",
                value: dayjs().add(data.expires_in, "seconds"),
              },
            });
            dispatch({
              type: mainActions.SET_TOKEN,
              token: {
                name: "googleAccessToken",
                value: data.access_token,
              },
            });
            refreshed = true;
          } else {
            refreshed = false;
            message = "Error refreshing Google access token. Please reconnect.";
          }
        } catch (error) {
          refreshed = false;
          message = "Error parsing Google access token. Please reconnect.";
        }
      } else {
        refreshed = false;
        message = "Unable to refresh Google access token. Please reconnect.";
      }
    } else {
      refreshed = true;
    }
    if (!refreshed) {
      dispatch({
        type: mainActions.SET_TOKEN,
        token: {
          name: "googleTokenExpires",
          value: null,
        },
      });
      dispatch({
        type: mainActions.SET_TOKEN,
        token: {
          name: "googleAccessToken",
          value: null,
        },
      });
      dispatch({
        type: mainActions.UPDATE_ALERT,
        alert: {
          show: true,
          message,
        },
      });
      return {
        name: action.calendar.name,
        events: [],
        alert: true,
        message,
      };
    }
    switch (action.type) {
      case "getCalendarEvents":
        url = calendarBaseUrl;
        url += action.calendar.id + "/events";
        url +=
          "?timeMin=" + action.calendar.viewDate.startOf("month").toISOString();
        url +=
          "&timeMax=" + action.calendar.viewDate.endOf("month").toISOString();
        url += "&showDeleted=false&singleEvents=true";
        url += "&maxResults=" + configEntzy.PAGE_SIZE_CALENDAR;
        url += "&orderBy=startTime";
        response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });
        if (!response.ok) {
          result = {
            name: action.calendar.name,
            events: [],
            alert: true,
            message: "Error connecting to Google calendar",
          };
        } else {
          const body = await response.json();
          result = {
            name: action.calendar.name,
            events: body.items,
          };
        }
        break;
      case "createCalendarEntry":
        url = calendarBaseUrl;
        url += action.calendar.id + "/events";
        payload = {
          summary: action.entry.ExternalDescription,
          start: {
            date: action.entry.DateKey,
          },
          end: {
            date: action.entry.DateKey,
          },
          location: "Entzy",
        };
        response = await fetch(url, {
          method: "POST",
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
        if (!response.ok) {
          result = {
            name: action.calendar.name,
            alert: true,
            message: "Error connecting to Google calendar",
          };
        } else {
          const body = await response.json();
          result = {
            name: action.calendar.name,
            entry: body,
          };
        }
        break;
      case "updateCalendarEntry":
        url = calendarBaseUrl;
        url += action.calendar.id + "/events/" + action.entry.DateId;
        payload = {
          summary: action.entry.ExternalDescription,
          start: {
            date: action.entry.DateKey,
          },
          end: {
            date: action.entry.DateKey,
          },
          location: "Entzy",
        };
        response = await fetch(url, {
          method: "PUT",
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });
        if (!response.ok) {
          result = {
            name: action.calendar.name,
            alert: true,
            message: "Error connecting to Google calendar",
          };
        } else {
          const body = await response.json();
          result = {
            name: action.calendar.name,
            entry: body,
          };
        }
        break;
      case "deleteCalendarEntry":
        url = calendarBaseUrl;
        url += action.calendar.id + "/events/" + action.entry.DateId;
        response = await fetch(url, {
          method: "DELETE",
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "application/json",
          },
        });
        result = {
          name: action.calendar.name,
          entry: {
            id: action.entry.DateId,
          },
        };
        if (!response.ok && response.status !== 410) {
          result.alert = true;
          result.message = "Error connecting to Google calendar";
        }
        break;
      case "getCalendarInfo":
        url = calendarBaseUrl;
        url += action.calendar.id;
        response = await fetch(url, {
          method: "GET",
          headers: {
            Authorization: "Bearer " + accessToken,
          },
        });
        if (!response.ok) {
          result = {
            name: action.calendar.name,
            calendar: {},
            alert: true,
            message: "Error connecting to Google calendar",
          };
        } else {
          const body = await response.json();
          result = {
            name: action.calendar.name,
            calendar: body,
          };
        }
        break;
      default:
        result = {
          name: action.calendar.name,
          alert: true,
          message: "Please select a valid action",
        };
        break;
    }
    return result;
  };
  const cbPrepareCallGoogleApi = useCallback(prepareCallGoogleApi, [
    state.viewer.tokens,
    cbPrepareOauthFlow,
  ]);

  const prepareUserSetting = async (params) => {
    const userConnected = getUserConnected(params);
    if (userConnected) {
      return serviceUserSetting(params);
    } else {
      return {
        alert: true,
        message: "Please connect to manage settings",
      };
    }
  };

  // TODO: create a batch get user for this function
  const prepareInteractWithUser = async (params) => {
    let response, userDetails, appUser, appEmptyUser;
    // fallback empty user
    appEmptyUser = newUser();
    appEmptyUser.identity = params.identity;
    appEmptyUser.name = params.name ? params.name : "Anonymous";
    appEmptyUser.sub = null;
    appEmptyUser.username = null;
    appEmptyUser.error = true;
    appEmptyUser.message = "User not found, inactive or anonymous";
    appEmptyUser.email = null;
    appEmptyUser.avatar = {
      hydrated: true,
      key: configEntzy.EMPTY_STRING_SET,
      images: {
        sm: { success: false },
        md: { success: false },
        lg: { success: false },
      },
    };
    // check if user is connected and return details
    const userConnected = cbGetUserConnected(params);
    if (userConnected && (params.identity || params.name)) {
      if (params.identity === configEntzy.AI_USER_ID) {
        // return the ai user avatar
        appEmptyUser = newUser();
        appEmptyUser.identity = params.identity;
        appEmptyUser.name = configEntzy.AI_USER_NAME;
        appEmptyUser.sub = params.identity;
        appEmptyUser.username = configEntzy.AI_USER_NAME;
        appEmptyUser.avatar = {
          hydrated: true,
          key: "ai-avatar",
          images: {
            sm: { data: aiAvatar, success: true },
            md: { data: aiAvatar, success: true },
            lg: { data: aiAvatar, success: true },
          },
        };
      } else if (params.name && !params.identity) {
        response = await serviceGraphCall("query", "getUserName", {
          UserName: params.name,
        });
        if (response.success && response.data) {
          params.identity = response.data.split("::")[0];
        } else {
          params.identity = null;
        }
      }
      response = await serviceGraphCall("query", "getUserDetails", {
        UserId: params.identity,
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      if (response.data) {
        userDetails = response.data;
      } else {
        userDetails = {
          Status: "notfound",
        };
      }
      if (userDetails.Status === "active") {
        appUser = newUser();
        appUser.identity = userDetails.UserId;
        appUser.sub = userDetails.SubId;
        appUser.username = userDetails.SubId;
        appUser.name = userDetails.Name;
        appUser.email = userDetails.Email;
        appUser.avatar = {
          hydrated: false,
          key: userDetails.Avatar,
          images: {},
        };
        if (
          appUser.avatar.key &&
          appUser.avatar.key !== configEntzy.EMPTY_STRING_SET &&
          appUser.avatar.key !== configEntzy.EMPTY_STRING_SHORTSET
        ) {
          const images = await Promise.all([
            serviceObjectDownload(appUser.avatar.key, "sm", appUser.identity),
            serviceObjectDownload(appUser.avatar.key, "md", appUser.identity),
            serviceObjectDownload(appUser.avatar.key, "lg", appUser.identity),
          ]);
          appUser.avatar.hydrated = true;
          images.forEach((item) => {
            if (item.success) {
              appUser.avatar.images[item.size] = item;
            } else {
              // any failures deactivate avatar
              appUser.avatar.hydrated = false;
            }
          });
        }
        return {
          data: appUser,
        };
      } else {
        return {
          data: appEmptyUser,
        };
      }
    } else {
      return {
        data: appEmptyUser,
      };
    }
  };
  const cbPrepareInteractWithUser = useCallback(prepareInteractWithUser, [
    cbGetUserConnected,
  ]);

  const prepareInteractWithImage = async (params) => {
    // fallback empty imageset
    const appEmptyImageSet = {
      id: params.id,
      key: params.key,
      hydrated: false,
      images: {
        sm: { success: false },
        md: { success: false },
        lg: { success: false },
      },
    };
    // check if user is connected and return details
    const userConnected = getUserConnected(params);
    if (userConnected || (params.key && params.key.endsWith(":public"))) {
      if (
        params.key &&
        params.key !== configEntzy.EMPTY_STRING_SET &&
        params.key !== configEntzy.EMPTY_STRING_SHORTSET
      ) {
        const images = await Promise.all([
          serviceObjectDownload(params.key, "sm"),
          serviceObjectDownload(params.key, "md"),
          serviceObjectDownload(params.key, "lg"),
        ]);
        appEmptyImageSet.hydrated = true;
        images.forEach((item) => {
          if (item.success) {
            appEmptyImageSet.images[item.size] = item;
          } else {
            // any failures deactivate imageset
            appEmptyImageSet.hydrated = false;
          }
        });
      }
      return {
        data: appEmptyImageSet,
      };
    } else {
      return {
        data: appEmptyImageSet,
      };
    }
  };

  const generateCalendarDay = (date, entries) => {
    return {
      DateKey: date.format("YYYY-MM-DD"),
      DateDisplay: date.format("ddd DD MMM"),
      Launches: entries.filter((item) => item.Category.includes("launch")),
      Runners: entries.filter((item) => item.Category.includes("runner")),
      Riders: entries.filter((item) => item.Category.includes("rider")),
      Events: entries.filter((item) => item.Category.includes("event")),
      Direct: entries.filter((item) => item.Category.includes("direct")),
      External: entries.filter((item) => item.Category.includes("external")),
      Entries: entries,
    };
  };
  const generateCalendarMonth = async (viewDate, items) => {
    const startMonth = viewDate.startOf("month");
    const endMonth = viewDate.endOf("month");
    const dates = [];
    for (let date = startMonth; date <= endMonth; date = date.add(1, "day")) {
      const entries = items.filter(
        (item) => item.DateKey === date.format("YYYY-MM-DD")
      );
      // sort entries by description if exists otherwise by category
      entries.sort((a, b) =>
        a.Description > b.Description
          ? 1
          : a.Description < b.Description
          ? -1
          : a.Category > b.Category
          ? 1
          : a.Category < b.Category
          ? -1
          : 0
      );
      dates.push(generateCalendarDay(date, entries));
    }
    return {
      monthId: viewDate.format("YYYY-MM"),
      viewDate: viewDate.format("YYYY-MM-DD"),
      data: dates,
    };
  };
  const cbGenerateCalendarMonth = useCallback(generateCalendarMonth, []);

  const preparePullViewerCalendar = async (params) => {
    let response;
    if (!params) {
      params = {};
    }
    if (!params.viewDate) {
      params.viewDate = dayjs();
    }
    const userConnected = cbGetUserConnected(params);
    if (userConnected) {
      const stateMonthData =
        state.viewer.calendar[params.viewDate.format("YYYY-MM")];
      const stateMonthExpires = stateMonthData
        ? dayjs().diff(stateMonthData.expires, "minutes")
        : 0;
      if (stateMonthData && stateMonthExpires < 0 && !params.noCache) {
        const cachedMonthData = {
          data: JSON.parse(JSON.stringify(stateMonthData.data)),
        };
        cachedMonthData.nextToken = stateMonthData.nextToken;
        cachedMonthData.more = stateMonthData.nextToken ? true : false;
        cachedMonthData.viewDate = params.viewDate.format("YYYY-MM-DD");
        cachedMonthData.monthId = params.viewDate.format("YYYY-MM");
        cachedMonthData.expires = stateMonthData.expires;
        return cachedMonthData;
      }
      // call timeline
      response = await serviceGraphCall("query", "viewCalendarTimelineList", {
        Year: params.viewDate.format("YYYY"),
        Month: params.viewDate.format("MM"),
        nextToken: params.nextToken,
        limit: configEntzy.PAGE_SIZE_CALENDAR,
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      const entries = response.data.items;
      // check for external calendars
      let externalCalendars, tokenOverrides;
      if (state.viewer.calendar.externalHydrated) {
        externalCalendars = state.viewer.calendar.external;
        tokenOverrides = {
          ...params.tokenOverrides,
        };
      } else {
        externalCalendars = [];
        tokenOverrides = {};
        // loop through external calendars and hydrate
        for (const calendar of state.viewer.calendar.external) {
          response = await cbPrepareOauthFlow({
            provider: calendar.name,
          });
          if (response.success) {
            try {
              const data = JSON.parse(response.data);
              if (!data.error) {
                externalCalendars.push({
                  name: calendar.name,
                  connected: true,
                });
                tokenOverrides[calendar.name + "AccessToken"] =
                  data.access_token;
                tokenOverrides[calendar.name + "TokenExpires"] = dayjs().add(
                  data.expires_in,
                  "seconds"
                );
                dispatch({
                  type: mainActions.SET_TOKEN,
                  token: {
                    name: calendar.name + "TokenExpires",
                    value: dayjs().add(data.expires_in, "seconds"),
                  },
                });
                dispatch({
                  type: mainActions.SET_TOKEN,
                  token: {
                    name: calendar.name + "AccessToken",
                    value: data.access_token,
                  },
                });
              }
            } catch (error) {
              serviceLogError("preparePullViewerCalendar-External", {
                error,
                message: "Error parsing external calendar token",
              });
            }
          }
          // if calendar not found
          if (!externalCalendars.find((item) => item.name === calendar.name)) {
            externalCalendars.push({
              name: calendar.name,
              connected: false,
            });
          }
        }
        dispatch({
          type: mainActions.SET_EXTERNAL_CALENDARS,
          calendars: externalCalendars,
        });
      }
      // google calendar
      let googleCalendarInfo, googleEvents, googleAccessToken;
      // is token already active or was it passed in during a state change
      googleAccessToken = tokenOverrides.googleAccessToken
        ? tokenOverrides.googleAccessToken === "null"
          ? null
          : tokenOverrides.googleAccessToken
        : state.viewer.tokens.googleAccessToken
        ? state.viewer.tokens.googleAccessToken.value
        : null;
      // merge google calendar events if connected and token exists
      if (googleAccessToken) {
        try {
          // first get primary calendar info if not already hydrated
          if (!state.viewer.calendar.externalMeta["google"]) {
            googleCalendarInfo = await cbPrepareCallGoogleApi({
              type: "getCalendarInfo",
              calendar: {
                name: "google",
                id: "primary",
              },
              googleOverrideAccessToken: tokenOverrides.googleAccessToken,
            });
            dispatch({
              type: mainActions.SET_EXTERNAL_CALENDAR_META,
              calendar: {
                name: "google",
                meta: googleCalendarInfo.calendar,
              },
            });
          }
          // then get events for primary calendar
          googleEvents = await cbPrepareCallGoogleApi({
            type: "getCalendarEvents",
            calendar: {
              name: "google",
              id: "primary",
              viewDate: params.viewDate,
            },
            googleOverrideAccessToken: tokenOverrides.googleAccessToken,
          });
          let dateKey, startTime, endTime, allDay, description, location, color;
          googleEvents.events.forEach((item) => {
            dateKey = item.start.dateTime
              ? item.start.dateTime.substr(0, 10)
              : item.start.date;
            startTime = item.start.dateTime
              ? dayjs(item.start.dateTime)
              : item.start.date
              ? dayjs(item.start.date, "YYYY-MM-DD").startOf("day")
              : dayjs(dateKey, "YYYY-MM-DD");
            endTime = item.end.dateTime
              ? dayjs(item.end.dateTime)
              : item.end.date
              ? dayjs(item.end.date, "YYYY-MM-DD").endOf("day")
              : startTime.add(1, "hour");
            allDay = !item.start.dateTime && item.start.date ? true : false;
            description = item.summary ? item.summary : "Untitled";
            description += "\n\n";
            description += item.description ? item.description : "";
            description =
              (allDay
                ? ""
                : startTime.format("HH:mm") +
                  " (" +
                  endTime.diff(startTime, "minutes") +
                  "m) ") + description;
            location = item.location;
            color = googleConvertColorId(item.colorId);
            entries.push({
              DateKey: dateKey,
              DateId: item.id,
              Category: "external-entry",
              Description: description,
              DateMeta: JSON.stringify({
                StartTime: startTime.format("HH:mm"),
                EndTime: endTime.format("HH:mm"),
                AllDay: allDay,
                Location: location,
                Color: color,
              }),
              EventUrl: item.htmlLink,
              UserId: item.creator ? item.creator.email : user.identity,
            });
          });
        } catch (error) {
          serviceLogError("preparePullViewerCalendar-Google", {
            error,
            message: "Error pulling google calendar events",
          });
        }
      }
      // output populated timeline for active month
      const monthData = await cbGenerateCalendarMonth(params.viewDate, entries);
      monthData.nextToken = response.data.nextToken;
      monthData.more = params.nextToken ? true : false;
      return monthData;
    } else {
      // output empty timeline for guest user
      const monthData = await cbGenerateCalendarMonth(params.viewDate, []);
      return monthData;
    }
  };
  const cbPreparePullViewerCalendar = useCallback(preparePullViewerCalendar, [
    cbGetUserConnected,
    cbGenerateCalendarMonth,
    cbPrepareOauthFlow,
    cbPrepareCallGoogleApi,
    state.viewer.calendar,
    state.viewer.tokens,
    user.identity,
  ]);

  const prepareUpdateCalendarMonthData = async (update) => {
    const monthData = {
      monthId: update.monthId,
      viewDate: update.viewDate,
      data: JSON.parse(
        JSON.stringify(
          update.monthData
            ? update.monthData.data
            : state.viewer.calendar[update.monthId].data
        )
      ),
    };
    if (update.entry && update.entry.DateKey && update.entry.DateId) {
      const entryIndex = monthData.data.findIndex(
        (item) => item.DateKey === update.entry.DateKey
      );
      const entryList = monthData.data[entryIndex].Entries;
      let newEntryList;
      if (update.delete) {
        if (entryIndex > -1) {
          newEntryList = entryList.filter(
            (item) => item.DateId !== update.entry.DateId
          );
        }
      } else if (update.create) {
        if (entryIndex > -1) {
          newEntryList = entryList.concat(update.entry);
        } else {
          newEntryList = [update.entry];
        }
      } else {
        if (entryIndex > -1) {
          newEntryList = entryList.map((item) =>
            item.DateId === update.entry.DateId ? update.entry : item
          );
        } else {
          newEntryList = [update.entry];
        }
      }
      monthData.data[entryIndex] = generateCalendarDay(
        update.viewDate,
        newEntryList
      );
      return {
        data: update.entry,
        monthData: monthData,
      };
    } else {
      return {
        alert: true,
        message: "Please provide valid entry details",
      };
    }
  };

  const prepareUpdateExternalCalendarEntry = async (update) => {
    let response;
    const userConnected = getUserConnected(update);
    if (userConnected) {
      update.monthId = update.entry.DateKey.split("-").slice(0, 2).join("-");
      update.viewDate = dayjs(update.entry.DateKey, "YYYY-MM-DD");
      if (!update.calendar) {
        return {
          alert: true,
          message: "A valid calendar required for this update",
        };
      }
      if (update.calendar.name === "google") {
        response = await cbPrepareCallGoogleApi({
          type: update.create
            ? "createCalendarEntry"
            : update.delete
            ? "deleteCalendarEntry"
            : "updateCalendarEntry",
          calendar: {
            name: "google",
            id: "primary",
          },
          entry: update.entry,
        });
        if (response.alert) {
          return response;
        }
        update.entry = {
          DateKey: update.entry.DateKey,
          DateId: response.entry.id,
          Description: response.entry.summary,
          Category: "external-entry",
          EventUrl: response.entry.htmlLink,
          UserId: response.entry.creator
            ? response.entry.creator.email
            : user.identity,
        };
      }
      return update;
    } else {
      return {
        alert: true,
        message: "Please connect to manage calendar entries",
      };
    }
  };

  const prepareUpdateCalendarEntry = async (update) => {
    let response;
    const userConnected = getUserConnected(update);
    if (userConnected) {
      update.monthId = update.entry.DateKey.split("-").slice(0, 2).join("-");
      update.viewDate = dayjs(update.entry.DateKey, "YYYY-MM-DD");
      const mutation = update.create
        ? "createCalendarTimelineEntry"
        : update.delete
        ? "deleteCalendarTimelineEntry"
        : "updateCalendarTimelineEntry";
      const payload = {
        DateKey: update.entry.DateKey,
        DateId: update.entry.DateId,
        Description: update.entry.Description,
        DateMeta: update.entry.DateMeta
          ? JSON.stringify(update.entry.DateMeta)
          : null,
      };
      response = await serviceGraphCall("mutation", mutation, payload);
      if (!response.success) {
        response.alert = true;
        return response;
      }
      update.entry = response.data ? response.data : update.entry;
      return update;
    } else {
      return {
        alert: true,
        message: "Please connect to manage calendar entries",
      };
    }
  };

  const preparePullViewerEvents = async (params) => {
    let response;
    const userConnected = cbGetUserConnected(params);
    if (userConnected) {
      response = await serviceGraphCall("query", "viewEvents", {
        nextToken: params.nextToken,
        limit: configEntzy.PAGE_SIZE_EVENTS,
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      return {
        data: response.data.items,
        nextToken: response.data.nextToken,
        more: params.nextToken ? true : false,
      };
    } else {
      return {
        alert: true,
        message: "Please connect to manage eventuators",
      };
    }
  };
  const cbPreparePullViewerEvents = useCallback(preparePullViewerEvents, [
    cbGetUserConnected,
  ]);

  const prepareArchiveEvent = async (update) => {
    let response;
    const userConnected = getUserConnected(update);
    if (userConnected) {
      const mutation = update.unarchive ? "deleteInterest" : "createInterest";
      const payload = {
        EventId: update.EventId,
        Url: update.Url,
        Category: "events",
        SubCategory: "archived",
      };
      response = await serviceGraphCall("mutation", mutation, payload);
      if (!response.success) {
        response.alert = true;
        return response;
      }
      update.response = response.data;
      return update;
    } else {
      return {
        alert: true,
        message: "Please connect to manage events",
      };
    }
  };
  // const prepareRemoveEvent = async (update) => {
  //   let response;
  //   const userConnected = getUserConnected(update);
  //   if (userConnected) {
  //     // if owner then deactivate launching first
  //     // this prevents orphaned active events
  //     if (update.owner) {
  //       response = await serviceGraphCall("query", "publicViewEvent", {
  //         Url: update.Url,
  //       });
  //       if (response.success) {
  //         response.data.Active = false;
  //         response = await serviceGraphCall(
  //           "mutation",
  //           "updateEvent",
  //           response.data
  //         );
  //       }
  //     }
  //     // then remove the event from the user feed
  //     // get all user interests for this event
  //     response = await serviceGraphCall("query", "getInterestList", {
  //       EventId: update.EventId,
  //       Url: update.Url,
  //       Category: "events",
  //       HashFilter: update.EventId,
  //     });
  //     if (!response.success) {
  //       response.alert = true;
  //       return response;
  //     } else {
  //       // loop through response and delete each interest item
  //       const promises = response.data.items
  //         .filter((obj) => obj.HashId === update.EventId)
  //         .map(async (item) => {
  //           const payload = {
  //             EventId: update.EventId,
  //             Url: update.Url,
  //             Category: "events",
  //             SubCategory: item.InterestId.split(":")[1],
  //           };
  //           response = await serviceGraphCall(
  //             "mutation",
  //             "deleteInterest",
  //             payload
  //           );
  //           if (!response.success) {
  //             response.alert = true;
  //             return response;
  //           }
  //           return response.data;
  //         });
  //       response = await Promise.all(promises);
  //       return {
  //         EventId: update.EventId,
  //         response: response,
  //       };
  //     }
  //   } else {
  //     return {
  //       alert: true,
  //       message: "Please connect to manage events",
  //     };
  //   }
  // };

  const preparePullNotifications = async (params) => {
    const userConnected = cbGetUserConnected(params);
    if (userConnected) {
      const response = await serviceGraphCall("query", "getNotifications", {
        Category: params.Category,
        limit: configEntzy.PAGE_SIZE_NOTIFICATIONS,
        nextToken: params.nextToken,
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      response.more = params.nextToken ? true : false;
      return response;
    } else {
      return {
        data: [],
      };
    }
  };
  const cbPreparePullNotifications = useCallback(preparePullNotifications, [
    cbGetUserConnected,
  ]);

  const prepareUpdateNotification = async (params) => {
    const userConnected = cbGetUserConnected(params);
    if (userConnected) {
      const response = await serviceGraphCall(
        "mutation",
        "pushUpdateNotification",
        {
          NotificationId: params.notification.NotificationId,
          NotificationAction: params.action,
        }
      );
      if (!response.success) {
        response.alert = true;
        return response;
      }
      if (params.action.startsWith("mark")) {
        params.notification.MessageRead =
          params.action === "markread" ? true : false;
      }
      return {
        data: params.notification,
        removed: params.action === "remove",
      };
    } else {
      return {
        data: [],
      };
    }
  };

  const preparePullMemberMessages = async (params) => {
    let response;
    const userConnected = getUserConnected(params);
    if (userConnected) {
      response = await serviceGraphCall("query", "getChatDirectList", {
        RoomArea: params.RoomArea,
        RoomName: params.RoomName,
        Category: params.Category,
        // DateFragment: params.DateFragment,
        // DateFragment: "runner",
        nextToken: params.nextToken,
        limit: configEntzy.PAGE_SIZE_MESSAGING,
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      const roomId = params.RoomArea + ":" + params.RoomName;
      const dataItems =
        params.more && state.memberMessaging[roomId]
          ? state.memberMessaging[roomId].data.combined.concat(
              response.data.items
            )
          : response.data.items;
      // first loop through messages and set category as it is dependent on who is viewer
      // for direct messaging set viewer as runner (message sender) and other participant as rider (message receiver)
      dataItems.forEach((item, index, data) => {
        data[index].Category =
          data[index].UserId === user.identity ? "runner" : "rider";
      });
      // loop through messages for formatting and flag if previous message is from same user
      dataItems.forEach((item, index, data) => {
        if (index === 0) {
          data[index].PriorUserId = dataItems[0].UserId;
          data[index].PriorCategory = dataItems[0].Category;
          data[index].PriorPostDeleted = dataItems[0].PostDeleted;
        } else {
          data[index].PriorUserId = dataItems[index - 1].UserId;
          data[index].PriorCategory = dataItems[index - 1].Category;
          data[index].PriorPostDeleted = dataItems[index - 1].PostDeleted;
          if (item.UserId !== dataItems[index - 1].UserId) {
            data[index].PriorUserChanged = true;
          }
        }
        if (index === data.length - 1) {
          data[index].PriorUserEnd = true;
        }
      });
      // split messages into runner and rider
      const directMessages = {
        data: {
          combined: dataItems,
          runners: dataItems.filter((obj) => obj.Category === "runner"),
          riders: dataItems.filter((obj) => obj.Category === "rider"),
          nextToken: response.data.nextToken,
        },
      };
      return directMessages;
    } else {
      return {
        data: {
          combined: [],
          runners: [],
          riders: [],
        },
      };
    }
  };

  const preparePostMemberMessage = async (post) => {
    // post group message
    let response;
    const userConnected = getUserConnected(post);
    // bundle.id = "T0" + dayjs().valueOf();
    if (userConnected) {
      // for file or other media uploads
      // response = await serviceObjectChatUpload(bundle.eventid, bundle, []);
      // if (!response.success) {
      //   response.alert = true;
      //   return response;
      // }
      // const bundleKey = response.data.bundleKey;
      response = await serviceGraphCall("mutation", "postChatDirectContent", {
        RoomArea: post.RoomArea,
        RoomName: post.RoomName,
        ContentType: post.ContentType,
        ContentData: post.ContentData,
        ContentImage: post.ContentImage,
      });
      if (!response.success) {
        response.alert = true;
      }
      response.ImagePreload = post.ImagePreload;
      return response;
    } else {
      // mock message post for runner explore mode
      if (post.Category === "runner") {
        const timestamp = dayjs().toISOString();
        return {
          success: true,
          data: {
            Category: post.Category,
            RoomArea: post.RoomArea,
            RoomName: post.RoomName,
            UserId: post.UserId,
            ContentData: post.ContentData,
            ContentType: post.ContentType,
            FirstCreated: timestamp,
            LastUpdated: timestamp,
          },
        };
      } else {
        return {
          alert: true,
          message: "Please connect to post messages",
        };
      }
    }
  };
  const prepareRemoveMemberMessage = async (post) => {
    let response;
    const userConnected = getUserConnected(post);
    if (userConnected) {
      response = await serviceGraphCall("mutation", "removeChatDirectContent", {
        RoomArea: post.RoomArea,
        RoomName: post.RoomName,
        PostId: post.PostId,
      });
      if (!response.success) {
        response.alert = true;
      }
      return response;
    } else {
      return {
        alert: true,
        message: "Please connect to remove messages",
      };
    }
  };

  const preparePullMemberChatSettings = async (params) => {
    let response;
    const userConnected = getUserConnected(params);
    if (userConnected) {
      const pushSetting = {
        SettingId:
          "alerts:push:room:" + params.RoomArea + ":" + params.RoomName,
      };
      const mailSetting = {
        SettingId:
          "alerts:mail:room:" + params.RoomArea + ":" + params.RoomName,
      };
      const blockedSetting = {
        SettingId:
          "alerts:blocked:room:" + params.RoomArea + ":" + params.RoomName,
      };
      // pull both push and mail settings
      response = await Promise.all([
        prepareUserSetting(pushSetting),
        prepareUserSetting(mailSetting),
        prepareUserSetting(blockedSetting),
      ]);
      const alertMessage =
        "Hmm there was a problem loading your chat notification settings. Contact us if this persists.";
      if (!response[0].success || !response[1].success) {
        return {
          alert: true,
          message: alertMessage,
        };
      }
      // if no notification settings exist yet then create as active
      if (!response[0].data) {
        response[0] = await prepareUserSetting({
          create: true,
          ...pushSetting,
          SettingValue: "active",
        });
      }
      if (!response[1].data) {
        response[1] = await prepareUserSetting({
          create: true,
          ...mailSetting,
          SettingValue: "active",
        });
      }
      if (!response[2].data) {
        response[2] = await prepareUserSetting({
          create: true,
          ...blockedSetting,
          SettingValue: "blocked",
        });
      }
      return {
        data: {
          push:
            response[0].data && response[0].data.SettingValue === "active"
              ? true
              : false,
          mail:
            response[1].data && response[1].data.SettingValue === "active"
              ? true
              : false,
          blocked:
            response[2].data && response[2].data.SettingValue === "active"
              ? true
              : false,
        },
      };
    } else {
      // do not alert if not connected while user is exploring
      return {
        alert: false,
        message: "Please connect to manage chat settings",
      };
    }
  };
  const prepareUpdateMemberChatSettings = async (params) => {
    let response, payload;
    const userConnected = getUserConnected(params);
    if (userConnected) {
      // prepare an interest payload for notification payload being changed
      payload = {
        SettingId:
          "alerts:" +
          params.Settings.changed +
          ":room:" +
          params.RoomArea +
          ":" +
          params.RoomName,
        SettingValue: params.Settings[params.Settings.changed]
          ? "active"
          : "inactive",
      };
      response = await prepareUserSetting({
        create: true,
        ...payload,
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      return params.Settings;
    } else {
      return {
        alert: true,
        message: "Please connect to manage chat settings",
      };
    }
  };

  const prepareSendPayment = async (payment) => {
    let response;
    const userConnected = getUserConnected(payment);
    if (userConnected) {
      response = await serviceGraphCall("mutation", "sendDirectPayment", {
        RecipientType: payment.RecipientType,
        RecipientName: payment.RecipientName,
        Currency: payment.Currency,
        Amount: payment.Amount,
        Description: payment.Description,
      });
      if (response.success) {
        payment.success = true;
      } else {
        payment.alert = true;
        payment.message = response.message;
      }
      return payment;
    } else {
      return {
        alert: true,
        message: "Please connect to send payments",
      };
    }
  };

  // only invoked when successful response from stripe
  const prepareUpdatePaymentMethod = async (update) => {
    let response, attributes;
    const userConnected = getUserConnected(update);
    if (userConnected) {
      // set and update user attributes
      attributes = {
        "custom:entzy_payment_set": "true",
      };
      response = await userUpdate(attributes);
      if (response.success) {
        update.user = response.data;
      } else {
        response.alert = true;
        return response;
      }
      // initiate a backend sync
      response = await serviceGraphCall("mutation", "syncUser", {
        Action: "profile",
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      // return key value updated
      return update;
    } else {
      return {
        alert: true,
        message: "Connect to make updates to payment details",
      };
    }
  };

  const preparePullProductList = async (params) => {
    const query = params.Url
      ? "publicGetEventProductList"
      : "publicGetUserProductList";
    const response = await serviceGraphCall("query", query, {
      Url: params.Url,
      Name: params.Name,
      List: params.List,
      Category: params.Category,
      limit: configEntzy.PAGE_SIZE_PRODUCTS,
      nextToken: params.nextToken,
    });
    if (!response.success) {
      response.alert = true;
      return response;
    }
    // set hydrated list identifier
    let listId = "";
    if (params.Url) {
      listId += "eventuator:" + params.Url;
    }
    if (params.Name) {
      listId += "member:" + params.Name;
    }
    if (params.Category) {
      listId += ":" + params.Category;
    }
    if (params.List) {
      listId += ":" + params.List;
    }
    response.data.listId = listId;
    // check for more
    response.more = params.nextToken ? true : false;
    return response;
  };

  const prepareCreateProduct = async (params) => {
    const userConnected = cbGetUserConnected(params);
    if (userConnected) {
      const mutation = params.Url ? "createEventProduct" : "createUserProduct";
      const response = await serviceGraphCall("mutation", mutation, {
        Url: params.Url,
        Name: params.Name,
        List: params.List,
        Category: params.Category,
        Currency: params.Currency,
        Amount: params.Amount,
        Description: params.Description,
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      response.more = params.nextToken ? true : false;
      return response;
    } else {
      return {
        data: [],
      };
    }
  };

  const prepareDeleteProduct = async (params) => {
    const userConnected = cbGetUserConnected(params);
    if (userConnected) {
      const mutation = params.Url ? "deleteEventProduct" : "deleteUserProduct";
      const response = await serviceGraphCall("mutation", mutation, {
        Url: params.Url,
        Name: params.Name,
        ProductId: params.ProductId,
      });
      if (!response.success) {
        response.alert = true;
        return response;
      }
      return response;
    } else {
      return {
        data: [],
      };
    }
  };

  const preparePullContactList = async (params) => {
    if (!cbGetUserConnected(params)) {
      return {
        alert: true,
        message: "Please connect to manage contacts",
      };
    }
    const response = await serviceGraphCall("query", "getUserContactList", {
      limit: configEntzy.PAGE_SIZE_CONTACTS,
      nextToken: params.nextToken,
    });
    if (!response.success) {
      response.alert = true;
      return response;
    }
    response.more = params.nextToken ? true : false;
    // hydrate user details
    const promises = response.data.items.map(async (item) => {
      const user = await cbPrepareInteractWithUser({
        identity: item.ContactUserId,
      });
      if (user.data) {
        item.User = user.data;
      }
      return item;
    });
    response.data.items = await Promise.all(promises);
    return response;
  };
  const cbPreparePullContactList = useCallback(preparePullContactList, [
    cbGetUserConnected,
    cbPrepareInteractWithUser,
  ]);

  const prepareCreateContact = async (params) => {
    if (!cbGetUserConnected(params)) {
      return {
        alert: true,
        message: "Please connect to manage contacts",
      };
    }
    const response = await serviceGraphCall("mutation", "createUserContact", {
      Name: params.Name,
      ContactUserId: params.ContactUserId,
    });
    if (!response.success) {
      response.alert = true;
      return response;
    }
    // hydrate user details
    const userDetails = await cbPrepareInteractWithUser({
      identity: response.data.ContactUserId,
    });
    if (userDetails.data) {
      response.data.User = userDetails.data;
    }
    return response;
  };

  const prepareDeleteContact = async (params) => {
    if (!cbGetUserConnected(params)) {
      return {
        alert: true,
        message: "Please connect to manage contacts",
      };
    }
    const response = await serviceGraphCall("mutation", "deleteUserContact", {
      ContactUserId: params.ContactUserId,
    });
    if (!response.success) {
      response.alert = true;
      return response;
    }
    if (!response.data) {
      response.alert = true;
      response.message =
        "Hmm unable to delete contact. Give it another try or contact us if this problem persists.";
    }
    return response;
  };

  // main context value to pass down
  const value = {
    state,
    prepareOauthFlow: prepareOauthFlow,
    prepareUserSetting: prepareUserSetting,
    prepareInteractWithUser: prepareInteractWithUser,
    interactWithUser: (user) => {
      dispatch({ type: mainActions.INTERACT_WITH_USER, user });
    },
    prepareInteractWithImage: prepareInteractWithImage,
    interactWithImage: (image) => {
      dispatch({ type: mainActions.INTERACT_WITH_IMAGE, image });
    },
    preparePullViewerCalendar: preparePullViewerCalendar,
    pullViewerCalendar: (calendar) => {
      dispatch({ type: mainActions.PULL_VIEWER_CALENDAR, calendar });
    },
    resetViewerCalendar: () => {
      dispatch({ type: mainActions.RESET_VIEWER_CALENDAR });
    },
    updateExternalCalendar: (calendar) => {
      dispatch({ type: mainActions.UPDATE_EXTERNAL_CALENDAR, calendar });
    },
    preparePullViewerEvents: preparePullViewerEvents,
    pullViewerEvents: (events) => {
      dispatch({ type: mainActions.PULL_VIEWER_EVENTS, events });
    },
    prepareUpdateCalendarMonthData: prepareUpdateCalendarMonthData,
    updateCalendarMonthData: (update) => {
      dispatch({ type: mainActions.UPDATE_CALENDAR_MONTH_DATA, update });
    },
    prepareUpdateExternalCalendarEntry: prepareUpdateExternalCalendarEntry,
    prepareUpdateCalendarEntry: prepareUpdateCalendarEntry,
    prepareArchiveEvent: prepareArchiveEvent,
    archiveEvent: (event) => {
      dispatch({ type: mainActions.ARCHIVE_EVENT, event });
    },
    // prepareRemoveEvent: prepareRemoveEvent,
    removeEvent: (event) => {
      dispatch({ type: mainActions.REMOVE_EVENT, event });
    },
    addEvent: (event, owner, manager) => {
      dispatch({ type: mainActions.ADD_EVENT, event, owner, manager });
    },
    preparePullNotifications: preparePullNotifications,
    pullNotifications: (notifications) => {
      dispatch({ type: mainActions.PULL_NOTIFICATIONS, notifications });
    },
    prepareUpdateNotification: prepareUpdateNotification,
    updateNotification: (notification) => {
      if (notification.removed) {
        dispatch({ type: mainActions.REMOVE_NOTIFICATION, notification });
      } else {
        dispatch({ type: mainActions.MARK_READ_NOTIFICATION, notification });
      }
    },
    preparePullMemberMessages: preparePullMemberMessages,
    pullMemberMessages: (content) => {
      dispatch({ type: mainActions.PULL_MEMBER_MESSAGES, content });
    },
    preparePullMemberChatSettings: preparePullMemberChatSettings,
    pullMemberChatSettings: (settings) => {
      dispatch({ type: mainActions.PULL_MEMBER_CHAT_SETTINGS, settings });
    },
    prepareUpdateMemberChatSettings: prepareUpdateMemberChatSettings,
    updateMemberChatSettings: (settings) => {
      dispatch({ type: mainActions.UPDATE_MEMBER_CHAT_SETTINGS, settings });
    },
    preparePostMemberMessage: preparePostMemberMessage,
    postMemberMessage: (message) => {
      dispatch({ type: mainActions.POST_MEMBER_MESSAGE, message });
    },
    prepareRemoveMemberMessage: prepareRemoveMemberMessage,
    removeMemberMessage: (message) => {
      dispatch({ type: mainActions.REMOVE_MEMBER_MESSAGE, message });
    },
    setMemberContactViewer: (viewer) => {
      dispatch({ type: mainActions.SET_MEMBER_CONTACT_VIEWER, viewer });
    },
    setMemberMessageViewer: (viewer) => {
      dispatch({ type: mainActions.SET_MEMBER_MESSAGE_VIEWER, viewer });
    },
    setMemberTransactionViewer: (viewer) => {
      dispatch({ type: mainActions.SET_MEMBER_TRANSACTION_VIEWER, viewer });
    },
    setPayDirectViewer: (viewer) => {
      dispatch({ type: mainActions.SET_PAY_DIRECT_VIEWER, viewer });
    },
    prepareSendPayment: prepareSendPayment,
    sendPayment: (payment) => {
      dispatch({ type: mainActions.SEND_PAYMENT, payment });
      dispatch({
        type: mainActions.UPDATE_ALERT,
        alert: {
          show: true,
          message: "Payment sent successfully",
          severity: "success",
        },
      });
    },
    prepareUpdatePaymentMethod: prepareUpdatePaymentMethod,
    updatePaymentMethod: (details) => {
      dispatch({ type: mainActions.UPDATE_USER, user: details.user });
    },
    preparePullProductList: preparePullProductList,
    pullProductList: (products) => {
      dispatch({ type: mainActions.PULL_PRODUCT_LIST, products });
    },
    prepareCreateProduct: prepareCreateProduct,
    createProduct: (product) => {
      dispatch({ type: mainActions.CREATE_PRODUCT, product });
    },
    prepareDeleteProduct: prepareDeleteProduct,
    deleteProduct: (product) => {
      dispatch({ type: mainActions.DELETE_PRODUCT, product });
    },
    preparePullContactList: preparePullContactList,
    pullContactList: (contacts) => {
      dispatch({ type: mainActions.PULL_CONTACT_LIST, contacts });
      contacts.data.items.forEach((item) => {
        if (item.User) {
          dispatch({
            type: mainActions.INTERACT_WITH_USER,
            user: { data: { ...item.User, contact: true } },
          });
        }
      });
    },
    prepareCreateContact: prepareCreateContact,
    createContact: (contact) => {
      dispatch({ type: mainActions.CREATE_CONTACT, contact });
      if (contact.data.User) {
        dispatch({
          type: mainActions.INTERACT_WITH_USER,
          user: { data: { ...contact.data.User, contact: true } },
        });
      }
    },
    prepareDeleteContact: prepareDeleteContact,
    deleteContact: (contact) => {
      dispatch({ type: mainActions.DELETE_CONTACT, contact });
      if (contact.data.User) {
        dispatch({
          type: mainActions.INTERACT_WITH_USER,
          user: { data: { ...contact.data.User, contact: false } },
        });
      }
    },
    updateViewerTrail: (trail) => {
      dispatch({ type: mainActions.UPDATE_VIEWER_TRAIL, trail });
    },
    updateAlert: (params) => {
      const alertParams = params
        ? {
            show: params.show || params.alert ? true : false,
            severity: params.severity,
            message: params.message ? params.message : "",
          }
        : { show: false, message: "" };
      dispatch({
        type: mainActions.UPDATE_ALERT,
        alert: alertParams,
      });
      if (alertParams.show) {
        setTimeout(() => {
          dispatch({
            type: mainActions.UPDATE_ALERT,
            alert: { show: false, message: "" },
          });
        }, configEntzy.ALERT_TIMEOUT_SECS * 1000);
      } else {
        // on alert clear also clear parent app alert
        props.appAlertClear();
      }
    },
    openSubscription: openSubscription,
    closeSubscription: closeSubscription,
    setToken: (token) => {
      dispatch({ type: mainActions.SET_TOKEN, token });
    },
    setObservablesTracker: (observablesTracker) => {
      dispatch({
        type: mainActions.SET_OBSERVABLES_TRACKER,
        observablesTracker,
      });
    },
    selectMenu: (menuId) => {
      dispatch({ type: mainActions.SELECT_MENU, menuId });
    },
    setCallbackLoader: (callbackLoader, callbackState) => {
      dispatch({
        type: mainActions.SET_CALLBACK_LOADER,
        callbackLoader,
        callbackState,
      });
    },
    setActionDrawer: (drawer) => {
      dispatch({ type: mainActions.SET_ACTION_DRAWER, drawer });
    },
  };

  // subscription openers and callbacks
  useEffect(() => {
    const openAllSubscriptions = async () => {
      mainObservables.list.forEach((type) => {
        cbOpenSubscription(type);
      });
      mainObservables.active = true;
      dispatch({
        type: mainActions.SET_OBSERVABLES_TRACKER,
        observablesTracker: { active: true },
      });
    };
    const closeAllSubscriptions = async () => {
      mainObservables.list.forEach((type) => {
        cbCloseSubscription(type);
      });
      mainObservables.active = false;
      dispatch({
        type: mainActions.SET_OBSERVABLES_TRACKER,
        observablesTracker: { active: false },
      });
    };
    if (user && user.connected) {
      if (pause) {
        // pause subscriptions when idle
        // console.log("Pause main closing all subcriptions");
        closeAllSubscriptions();
      } else {
        // open subscriptions when required
        openAllSubscriptions();
      }
    } else {
      if (mainObservables.active) {
        // cleanup on exit event
        // console.log("Unmount exit event closing all subcriptions");
        closeAllSubscriptions();
      }
    }
  }, [
    user,
    pause,
    state.menuSelected,
    cbOpenSubscription,
    cbCloseSubscription,
  ]);

  // subscription fallback cleanup on unmount of overall event context
  useEffect(() => {
    return () => {
      // console.log("Unmount context closing all subcriptions");
      mainObservables.list.forEach((type) => {
        if (mainObservables[type].active) {
          mainObservables[type].subscription.unsubscribe();
        }
        mainObservables[type].active = false;
      });
    };
  }, []);

  // pull notifications on mount or on regular interval
  useEffect(() => {
    const hydrateNotifications = async () => {
      const response = await cbPreparePullNotifications({
        Category: null,
      });
      if (response.alert) {
        serviceLogError("hydrateNotifications", response);
      } else {
        dispatch({
          type: mainActions.PULL_NOTIFICATIONS,
          notifications: response,
        });
        setHydratingNotifications(false);
      }
    };
    if (user.connected) {
      if (
        !state.notifications.hydrated &&
        !hydratingNotifications
        //  ||
        // (state.notifications.hydrated &&
        //   !hydrating &&
        //   dayjs().diff(state.notifications.expires, "minutes") > 0)
      ) {
        setHydratingNotifications(true);
        hydrateNotifications();
      }
    }
  }, [
    user.connected,
    hydratingNotifications,
    state.notifications.hydrated,
    state.notifications.expires,
    cbPreparePullNotifications,
  ]);

  // pull viewer calendar on mount
  // TODO: CACHING ON THIS QUERY
  useEffect(() => {
    const hydrateViewerCalendar = async () => {
      const response = await cbPreparePullViewerCalendar();
      if (response.alert) {
        dispatch({
          type: mainActions.UPDATE_ALERT,
          alert: {
            show: true,
            message: response.message,
          },
        });
      } else {
        dispatch({
          type: mainActions.PULL_VIEWER_CALENDAR,
          calendar: response,
        });
      }
    };
    // run hydrate if not cached
    if (
      !state.viewer.calendar.hydrated &&
      !state.alert.show &&
      !hydratingViewerCalendar
    ) {
      setHydratingViewerCalendar(true);
      hydrateViewerCalendar();
    }
  }, [
    state.viewer.calendar.hydrated,
    state.alert.show,
    hydratingViewerCalendar,
    cbPreparePullViewerCalendar,
  ]);

  // pull viewer events on mount
  // TODO: CACHING ON THIS QUERY
  useEffect(() => {
    const hydrateViewerEvents = async () => {
      const response = await cbPreparePullViewerEvents({
        nextToken: null,
      });
      if (response.alert) {
        dispatch({
          type: mainActions.UPDATE_ALERT,
          alert: {
            show: true,
            message: response.message,
          },
        });
      } else {
        dispatch({ type: mainActions.PULL_VIEWER_EVENTS, events: response });
      }
    };
    // run hydrate if not cached
    if (
      cbGetUserConnected() &&
      !state.viewer.events.hydrated &&
      !state.alert.show &&
      !hydratingViewerEvents
    ) {
      setHydratingViewerEvents(true);
      hydrateViewerEvents();
    }
  }, [
    state.viewer.events.hydrated,
    state.alert.show,
    hydratingViewerEvents,
    cbPreparePullViewerEvents,
    cbGetUserConnected,
  ]);

  // pull contacts on mount
  useEffect(() => {
    const hydrateContacts = async () => {
      const response = await cbPreparePullContactList({
        nextToken: null,
      });
      if (response.alert) {
        dispatch({
          type: mainActions.UPDATE_ALERT,
          alert: {
            show: true,
            message: response.message,
          },
        });
      } else {
        const contacts = response;
        dispatch({ type: mainActions.PULL_CONTACT_LIST, contacts });
        contacts.data.items.forEach((item) => {
          if (item.User) {
            dispatch({
              type: mainActions.INTERACT_WITH_USER,
              user: { data: { ...item.User, contact: true } },
            });
          }
        });
      }
    };
    // run hydrate if not cached
    if (
      cbGetUserConnected() &&
      !state.contactList.hydrated &&
      !state.alert.show &&
      !hydratingContacts
    ) {
      setHydratingContacts(true);
      hydrateContacts();
    }
  }, [
    state.contactList.hydrated,
    state.alert.show,
    hydratingContacts,
    cbPreparePullContactList,
    cbGetUserConnected,
  ]);

  // captured selected menu into state
  useEffect(() => {
    if (props.menuSelected && props.menuSelected.id) {
      dispatch({
        type: mainActions.SELECT_MENU,
        menuId: props.menuSelected.id,
      });
    }
  }, [props.menuSelected]);

  // add event to inbox if autojoining from invite
  useEffect(() => {
    if (autoJoinEvent && autoJoinEvent.data) {
      autoJoinEventClear();
      dispatch({
        type: mainActions.ADD_EVENT,
        event: autoJoinEvent.data,
        owner: false,
        manager: autoJoinEvent.manager,
      });
    }
  }, [autoJoinEvent, autoJoinEventClear]);

  // show alert toast if sent from parent top app level
  useEffect(() => {
    if (props.appAlert.show) {
      dispatch({
        type: mainActions.UPDATE_ALERT,
        alert: props.appAlert,
      });
    }
  }, [props.appAlert]);

  // add app user into interactions so always present
  useEffect(() => {
    if (user) {
      dispatch({
        type: mainActions.UPDATE_USER,
        user: user,
      });
      dispatch({
        type: mainActions.INTERACT_WITH_USER,
        user: { data: { ...user, self: true } },
      });
    }
  }, [user]);

  // console.log("[MAIN CONTEXT]", state);
  // console.log("[MAIN CALENDAR]", state.viewer.calendar);
  // console.log("[MAIN TRAIL]", state.viewer.trail);
  // console.log("[MAIN USER]", user);
  // console.log("[MAIN INTERACT]", state.interaction.users);
  // console.log("[OBSERVABLES]", mainObservables);

  return (
    <IdleTimerProvider
      timeout={1000 * 60 * configEntzy.IDLE_TIMEOUT_MINS}
      onPrompt={onPrompt}
      onIdle={onIdle}
      onActive={onActive}
      onAction={onAction}
    >
      <ThemeProvider theme={responsiveFontSizes(mainTheme)}>
        <MainContext.Provider value={value}>
          <Box
            id="anchor-main-content"
            className="box-default full-height bg-black-t75"
          >
            <Box className="box-default full-height">
              <MainNotifications {...props} />
              <Box className="box-default">
                {props.page === "/connecting" ? (
                  <ConnectFlow
                    user={user}
                    location={location}
                    navigate={navigate}
                    drawerConnectToggle={props.drawerConnectToggle}
                  />
                ) : !props.user.connected &&
                  ["develop", "staging"].includes(configEntzy.APP_ENV) ? (
                  <Container
                    maxWidth="xs"
                    sx={{
                      pt: configEntzy.APP_SPACING_XL,
                    }}
                  >
                    <ActionAlert
                      severity="info"
                      message="Please connect with a valid testing account to access
                        this app"
                    />
                  </Container>
                ) : (
                  <Box className="box-default">
                    <MainCloseBar {...props} />
                    <Box className="box-default">
                      {props.page === "/" ? (
                        <HomeIndex {...props} />
                      ) : props.page === "/events/runner" ? (
                        <EventIndex {...props} eventParts={eventParts} />
                      ) : (
                        <Container maxWidth="md" disableGutters>
                          {props.page === "/" ? (
                            <HomeIndex {...props} />
                          ) : props.page === "/about" ? (
                            <AboutIndex {...props} />
                          ) : props.page === "/admin" ? (
                            <AdminIndex {...props} />
                          ) : props.page.startsWith("/event") ? (
                            <EventIndex {...props} />
                          ) : props.page.startsWith("/contacts") ? (
                            <Box className="box-default">
                              {props.user.connected && (
                                <ContactsIndex {...props} />
                              )}
                            </Box>
                          ) : props.page.startsWith("/contact") ? (
                            <ContactIndex {...props} />
                          ) : props.page.startsWith("/legal") ? (
                            <LegalIndex {...props} />
                          ) : props.page.startsWith("/member") ? (
                            <MemberIndex {...props} />
                          ) : props.page.startsWith("/pay") ? (
                            <PayIndex {...props} />
                          ) : props.page.startsWith("/settings") ? (
                            <Box className="box-default">
                              {props.user.connected && (
                                <SettingsIndex {...props} />
                              )}
                            </Box>
                          ) : props.page === "/health" ? (
                            <Box
                              className="box-default"
                              sx={{ pt: configEntzy.APP_SPACING_HL2X }}
                            >
                              <Typography variant="h1">Hello You</Typography>
                            </Box>
                          ) : (
                            <CatchNotFound {...props} />
                          )}
                        </Container>
                      )}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </MainContext.Provider>
      </ThemeProvider>
    </IdleTimerProvider>
  );
}

export default MainContent;
