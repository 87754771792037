// entzy config
import configEntzy from "components/config/ConfigEntzy";

// material design
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle as iconFallback } from "@fortawesome/pro-thin-svg-icons";

export const FallbackMessage = (props) => {
  return (
    <Box
      className="box-default"
      sx={{
        textAlign: "left",
        height: "100%",
        pt: configEntzy.APP_SPACING_MD,
      }}
    >
      {props.split ? (
        <Grid container>
          <Grid item xs={6} sx={{ height: "100%" }}>
            <Box
              className="box-default"
              sx={{ pr: configEntzy.APP_SPACING_SM }}
            >
              <FallbackContent {...props} />
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ height: "100%" }}>
            <Box
              className="box-default"
              sx={{ pl: configEntzy.APP_SPACING_SM }}
            >
              <FallbackContent {...props} alignRight={true} />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Box
          className="box-default"
          sx={{
            pl: props.right ? configEntzy.APP_SPACING_SM : "0px",
            pr: props.left ? configEntzy.APP_SPACING_SM : "0px",
          }}
        >
          <FallbackContent {...props} />
        </Box>
      )}
    </Box>
  );
};

const FallbackContent = (props) => {
  return (
    <Box
      className="box-default"
      sx={{
        textAlign: "center",
      }}
    >
      <Box
        className={
          props.disabled
            ? "box-default bg-black-t10 shadow-light"
            : "box-default bg-black-t50 shadow-default"
        }
        sx={{
          height: configEntzy.AVATAR_SIZE_XL,
          p: configEntzy.APP_SPACING_LG,
          borderRadius: configEntzy.BORDER_SIZE_LG,
          // backgroundColor: props.disabled ? "divider" : "white",
        }}
      >
        <Box className="box-default" sx={{ mt: configEntzy.APP_SPACING_LG }}>
          <FontAwesomeIcon
            icon={iconFallback}
            className="fa-beat"
            style={{
              "--fa-animation-duration": "1.5s",
              "--fa-beat-scale": 1.1,
            }}
            size="2x"
            fixedWidth
          />
        </Box>
        <Box
          className="box-default"
          sx={{
            mt: configEntzy.APP_SPACING_MD,
            mb: configEntzy.APP_SPACING_LG,
          }}
        >
          <Typography variant="body2" align="center">
            {props.fallback}
          </Typography>
          {props.subText1 && (
            <Typography
              variant="body2"
              align="center"
              sx={{
                mt: configEntzy.APP_SPACING_SM,
              }}
            >
              {props.subText1}
            </Typography>
          )}
          {props.subText2 && (
            <Typography
              variant="body2"
              align="center"
              sx={{
                mt: configEntzy.APP_SPACING_SM,
              }}
            >
              {props.subText2}
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

// const FallbackContent = (props) => {
//   return (
//     <Box
//       className={
//         props.alignCenter
//           ? "box-default content-disabled"
//           : "box-default relative content-disabled"
//       }
//       sx={{
//         textAlign: props.alignRight
//           ? "right"
//           : props.alignCenter
//           ? "center"
//           : "left",
//         height: "100%",
//       }}
//     >
//       <Box className="box-default" sx={{ minHeight: "20vh" }}>
//         <Box
//           className={
//             props.alignRight
//               ? "box-abs bottom-right"
//               : props.alignCenter
//               ? "box-inline"
//               : "box-abs bottom-left"
//           }
//         >
//           <Box className="box-default" sx={{ p: configEntzy.APP_SPACING_SM }}>
//             <Box className="box-default">
//               <FontAwesomeIcon
//                 icon={iconFallback}
//                 className="fa-beat"
//                 style={{
//                   "--fa-animation-duration": "1.5s",
//                   "--fa-beat-scale": 1.1,
//                 }}
//                 size="2x"
//                 transform="up-10"
//                 fixedWidth
//               />
//             </Box>
//             <Box className="box-default">
//               <Typography variant="body2" noWrap={true}>
//                 {props.fallback}
//               </Typography>
//             </Box>
//           </Box>
//         </Box>
//       </Box>
//     </Box>
//   );
// };
