// react core
import { useContext, useState } from "react";

// date handling
import dayjs from "dayjs";

// material tabs
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// material accordion
// import Accordion from "@mui/material/Accordion";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import Grow from "@mui/material/Grow";

// material design
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// entzy context and componentDividerf
import configEntzy from "components/config/ConfigEntzy";
import { TextTag } from "components/utils/common/CommonTags";
import { ActionLoader } from "components/utils/common/CommonLoaders";
import {
  ActionBoxButton,
  MoreButton,
} from "components/utils/common/CommonButtons";

// entzy context and services
import { MainContext } from "components/main/MainContext";
import EventIndex from "pages/events/EventIndex";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faAtomAlt as iconEventuators } from "@fortawesome/pro-thin-svg-icons";
import { faMessageCaptions as iconMessages } from "@fortawesome/pro-thin-svg-icons";
import { faRocketLaunch as iconActvity } from "@fortawesome/pro-thin-svg-icons";
import { faCalendarDay as iconCalendar } from "@fortawesome/pro-solid-svg-icons";
import { faSquare as iconSquare } from "@fortawesome/pro-solid-svg-icons";
import { faAtomAlt as iconEventSolid } from "@fortawesome/pro-solid-svg-icons";

import { faInfoCircle as iconInfo } from "@fortawesome/pro-thin-svg-icons";
import { faCheck as iconRead } from "@fortawesome/pro-thin-svg-icons";
import { faWrench as iconEdit } from "@fortawesome/pro-thin-svg-icons";
import { faMessagePlus as iconNewDM } from "@fortawesome/pro-thin-svg-icons";
import { faClose as iconClose } from "@fortawesome/pro-thin-svg-icons";

import { faExternalLink as iconOpenUrl } from "@fortawesome/pro-solid-svg-icons";

function WidgetHome(props) {
  // const user = props.user;
  const mainContext = useContext(MainContext);
  const [tab, setTab] = useState(
    !isNaN(mainContext.state.viewer.trail.joinTab)
      ? mainContext.state.viewer.trail.joinTab
      : 1
  );
  // const [managing, setManaging] = useState(
  //   (tab === 0 && mainContext.state.viewer.trail.editingMessageFeed) ||
  //     (tab === 2 && mainContext.state.viewer.trail.editingActivityFeed)
  // );
  const [loading, setLoading] = useState(false);
  const [loadingId, setLoadingId] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [calendarView, setCalendarView] = useState(
    mainContext.state.viewer.trail.calendarView
  );

  const handleCalendarView = (view) => {
    const newCalendarView =
      view === true ? true : view === false ? false : !calendarView;
    setCalendarView(newCalendarView);
    mainContext.updateViewerTrail({ calendarView: newCalendarView });
  };

  const handleNavigateEvent = (item, url) => {
    if (url) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      if (!url.startsWith("http")) {
        mainContext.updateViewerTrail({ sourcePage: "home", joinTab: tab });
        mainContext.selectMenu("e");
        props.navigate(url);
        // handleMarkRead(item, true);
      }
    }
  };

  // const handleManaging = async () => {
  //   setManaging(!managing);
  // };
  const handleEditing = () => {
    if (tab === 0) {
      mainContext.updateViewerTrail({
        editingMessageFeed: !mainContext.state.viewer.trail.editingMessageFeed,
      });
      // setManaging(!mainContext.state.viewer.trail.editingMessageFeed);
    }
    if (tab === 2) {
      mainContext.updateViewerTrail({
        editingActivityFeed:
          !mainContext.state.viewer.trail.editingActivityFeed,
      });
      // setManaging(!mainContext.state.viewer.trail.editingActivityFeed);
    }
  };
  const handleMarkRead = async (notification, readStatus) => {
    setLoading(true);
    setLoadingId(notification.NotificationId);
    const response = await mainContext.prepareUpdateNotification({
      notification: notification,
      action: readStatus ? "markread" : "markunread",
    });
    if (!response.alert) {
      mainContext.updateNotification(response);
    }
    setLoading(false);
    setLoadingId(null);
    return response;
  };
  const handleRemove = async (notification) => {
    setLoading(true);
    setLoadingId(notification.NotificationId);
    const response = await mainContext.prepareUpdateNotification({
      notification: notification,
      action: "remove",
    });
    if (!response.alert) {
      mainContext.updateNotification(response);
    }
    setLoading(false);
    setLoadingId(null);
    return response;
  };

  const handlePullMore = async () => {
    setLoadingMore(true);
    const response = await mainContext.preparePullNotifications({
      nextToken: mainContext.state.notifications.data.nextToken,
      more: true,
    });
    if (response.alert) {
      mainContext.updateAlert(response);
    } else {
      mainContext.pullNotifications(response);
    }
    setLoadingMore(false);
  };

  // tab handling
  const handleTabs = (event, newTab) => {
    setTab(newTab);
  };
  function TabPanel(props) {
    const { children, value: tab, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={tab !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {tab === index && <Box className="box-default">{children}</Box>}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function tabProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  function tabLabel(item, index) {
    return (
      <Box
        className={
          "box-default" + (tab === index ? " bg-white-t25" : " bg-black")
        }
        sx={{
          // mt: item.id === "eventuating" ? configEntzy.APP_SPACING_SM3X : 0,
          pt: configEntzy.APP_SPACING_MD,
          pb: configEntzy.APP_SPACING_MD,
          borderRight: item.id === "eventuating" ? "1px solid #222" : "none",
          borderLeft: item.id === "eventuating" ? "1px solid #222" : "none",
          borderTopLeftRadius:
            item.id === "messages"
              ? configEntzy.BORDER_SIZE_LG
              : configEntzy.BORDER_SIZE_MD,
          borderTopRightRadius:
            item.id === "activity"
              ? configEntzy.BORDER_SIZE_LG
              : configEntzy.BORDER_SIZE_MD,
          borderBottomLeftRadius:
            item.id === "messages"
              ? configEntzy.BORDER_SIZE_LG
              : configEntzy.BORDER_SIZE_MD,
          borderBottomRightRadius:
            item.id === "activity"
              ? configEntzy.BORDER_SIZE_LG
              : configEntzy.BORDER_SIZE_MD,
        }}
      >
        <Typography
          variant="subtitle2"
          noWrap={true}
          sx={{
            overflow: "visible",
          }}
        >
          <span>
            <span className="fa-layers fa-fw fa-4x">
              <FontAwesomeIcon
                icon={item.icon}
                transform="shrink-4"
                style={{
                  color: "white",
                  textShadow: "0 0 1px #ccc",
                }}
              />
              {/* <FontAwesomeIcon
                icon={item.icon}
                transform="shrink-6"
                style={{
                  color: tab === index ? "black" : undefined,
                  textShadow: "0 0 1px #ccc",
                }}
              /> */}
              <span
                className={
                  "fa-layers-counter" +
                  (item.id === "messages"
                    ? " fa-layers-top-left"
                    : " fa-layers-top-right")
                }
                style={{
                  backgroundColor: item.count > 0 ? "red" : "white",
                  color: item.count > 0 ? "white" : "black",
                  border: item.count > 0 ? "1px solid red" : "1px solid black",
                  visibility: item.id === "eventuating" ? "hidden" : "visible",
                }}
              >
                {item.count}
              </span>
            </span>
          </span>
        </Typography>
        <Typography
          variant="subtitle2"
          color={tab === index ? "primary" : "white"}
          sx={{
            mt: configEntzy.APP_SPACING_SM,
          }}
        >
          <span>{item.id}</span>
        </Typography>
      </Box>
    );
  }

  const NotificationList = (props) => {
    return (
      <Box className="box-default">
        {props.notifications.map((item, index) => {
          let internalUrl = item.MessageUrl.replace(configEntzy.APP_URL, "")
            .replace(configEntzy.APP_LONG_URL, "")
            .replace(configEntzy.APP_LOCAL_URL, "")
            .replace(configEntzy.APP_LOCAL_CLOUD_URL, "");
          let displayUrl = internalUrl;
          let memberDirectMessage = false;
          if (internalUrl.startsWith("@")) {
            internalUrl = internalUrl.replace("@", "/@/");
            memberDirectMessage = true;
          }
          if (!memberDirectMessage) {
            if (item.MessageCategory.startsWith("message")) {
              internalUrl += "/debate";
            }
            if (item.MessageCategory.startsWith("activity")) {
              if (item.MessageCategory.endsWith("rider")) {
                internalUrl += "/riders";
              } else {
                internalUrl += "/eventuate";
              }
            }
          }

          // let isMessage =
          //   item.MessageCategory && item.MessageCategory.startsWith("message");
          let daysAgo = dayjs().diff(dayjs(item.LastUpdated), "days");
          return (
            <Box
              key={item.NotificationId}
              className="box-default"
              sx={{
                pb: configEntzy.APP_SPACING_MD,
              }}
            >
              <Box
                className="box-default rounded text-left shadow-default"
                sx={{
                  p: configEntzy.APP_SPACING_MD,
                  minWidth: configEntzy.AVATAR_SIZE_MD,
                  borderRadius: configEntzy.BORDER_SIZE_LG,
                  // borderStyle: "solid",
                  // borderWidth: item.MessageRead ? "0px" : "1px",
                  // borderColor: item.MessageRead
                  //   ? "bright.main"
                  //   : item.MessageCategory.startsWith("message")
                  //   ? "secondary.main"
                  //   : item.MessageCategory.startsWith("activity")
                  //   ? "secondary.main"
                  //   : "transparent",
                  backgroundColor: item.MessageRead ? "#555" : "white",
                  color: item.MessageRead ? "#999" : "black",
                }}
              >
                {/* ----- url click wrapper ----- */}
                <Box
                  className="box-default action-pointer"
                  onClick={() => {
                    handleNavigateEvent(item, internalUrl);
                  }}
                >
                  <Box className="box-default">
                    <Typography variant="h6" noWrap={true}>
                      <span>{displayUrl}&nbsp;&nbsp;</span>
                      <span style={{ float: "right" }}>
                        <FontAwesomeIcon icon={iconOpenUrl} />
                      </span>
                    </Typography>
                  </Box>

                  <Box
                    className="box-default"
                    sx={{
                      pt: configEntzy.APP_SPACING_SM,
                      mb: configEntzy.APP_SPACING_MD,
                    }}
                  >
                    <Divider sx={{ backgroundColor: "#ccc" }} />
                  </Box>

                  {/* ----- loader ----- */}
                  {loading && loadingId === item.NotificationId && (
                    <Box className="box-default zero-height text-center">
                      <Box
                        className="box-inline bg-white shadow-default"
                        sx={{
                          pl: configEntzy.APP_SPACING_XL,
                          pr: configEntzy.APP_SPACING_XL,
                          pt: configEntzy.APP_SPACING_MD,
                          pb: configEntzy.APP_SPACING_MD,
                          borderRadius: configEntzy.BORDER_SIZE_XL,
                          backgroundColor: item.MessageRead ? "#bbb" : "white",
                          opacity: 0.9,
                        }}
                      >
                        <ActionLoader size="md" color="secondary" />
                      </Box>
                    </Box>
                  )}

                  {/* ----- message title ----- */}
                  <Box className="box-default">
                    <Typography
                      variant={item.MessageRead ? "subtitle1" : "subtitle2"}
                      noWrap={true}
                    >
                      {item.MessageTitle}
                      {item.MessageRead && (
                        <em>
                          <span>&nbsp;&nbsp;</span>
                          <span>
                            (read)
                            {/* ({daysAgo} day{daysAgo === 1 ? "" : "s"} ago) */}
                          </span>
                        </em>
                      )}
                    </Typography>
                  </Box>

                  {/* main message content */}
                  <Box className="box-default">
                    <Typography
                      key={index}
                      variant="body2"
                      sx={{
                        whiteSpace: "pre-line",
                        overflowWrap: "break-word",
                        pt: configEntzy.APP_SPACING_MD,
                      }}
                    >
                      {item.MessageBody}
                    </Typography>
                  </Box>

                  {/* footer content */}
                  <Box
                    className="box-default"
                    sx={{
                      pt: configEntzy.APP_SPACING_MD2X,
                    }}
                  >
                    <Typography variant="body1">
                      <em>
                        <span>
                          {daysAgo} day{daysAgo === 1 ? "" : "s"} ago
                        </span>
                        {item.MessageRead && (
                          <span>
                            &nbsp;&nbsp;|&nbsp;&nbsp;Read&nbsp;&nbsp;
                            <span style={{ color: "green" }}>
                              <FontAwesomeIcon icon={iconRead} fixedWidth />
                            </span>
                          </span>
                        )}
                      </em>
                    </Typography>
                  </Box>
                </Box>

                {/* ----- footer controls ----- */}
                <Box
                  className="box-default text-center"
                  sx={{
                    mt: configEntzy.APP_SPACING_LG,
                    pt: configEntzy.APP_SPACING_MD,
                    pb: configEntzy.APP_SPACING_MD,
                    color: "white",
                    border: item.MessageRead
                      ? configEntzy.BORDER_DASH_DSM
                      : configEntzy.BORDER_DASH_GSM,
                    borderRadius: configEntzy.BORDER_SIZE_LG,
                  }}
                  hidden={
                    (tab === 0 &&
                      !mainContext.state.viewer.trail.editingMessageFeed) ||
                    (tab === 2 &&
                      !mainContext.state.viewer.trail.editingActivityFeed)
                  }
                >
                  <Box className="box-default">
                    <TextTag
                      size="md"
                      text={
                        <span>
                          &nbsp;&nbsp;Mark{" "}
                          {item.MessageRead ? "Unread" : "Read"}
                          &nbsp;&nbsp;
                        </span>
                      }
                      shadow={false}
                      rounded={true}
                      margin={true}
                      bgColor={
                        item.MessageRead ? "secondary.light" : "success.dark"
                      }
                      color={item.MessageRead ? "primary.main" : "white"}
                      onClick={() => handleMarkRead(item, !item.MessageRead)}
                    />
                    <TextTag
                      size="md"
                      text={<span>&nbsp;&nbsp;Remove&nbsp;&nbsp;</span>}
                      shadow={false}
                      rounded={true}
                      margin={true}
                      bgColor="error.main"
                      onClick={() => handleRemove(item)}
                    />
                  </Box>
                  {/* any info messages */}
                  <Box
                    className="box-default"
                    sx={{
                      pt: configEntzy.APP_SPACING_SM2X,
                      pb: configEntzy.APP_SPACING_XS3X,
                      pl: configEntzy.APP_SPACING_XS3X,
                      pr: configEntzy.APP_SPACING_XS3X,
                    }}
                  >
                    <Typography
                      variant="body1"
                      color={item.MessageRead ? "white" : "primary"}
                      noWrap
                    >
                      <FontAwesomeIcon icon={iconInfo} fixedWidth />
                      <span>
                        <span>&nbsp;&nbsp;</span>
                        <em>
                          <span>
                            This notification will auto expire in&nbsp;
                            {configEntzy.NOTIFICATION_EXPIRY_DAYS - daysAgo} day
                            {configEntzy.NOTIFICATION_EXPIRY_DAYS - daysAgo ===
                            1
                              ? ""
                              : "s"}
                          </span>
                        </em>
                        <span>&nbsp;&nbsp;</span>
                      </span>
                      <FontAwesomeIcon icon={iconInfo} fixedWidth />
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          );
        })}

        {mainContext.state.notifications.data.nextToken && (
          <Box className="box-default" sx={{ mt: configEntzy.APP_SPACING_LG }}>
            <MoreButton
              variant="contained"
              size="small"
              type="button"
              color="bright"
              text="More"
              loading={loadingMore}
              onClick={() =>
                handlePullMore(mainContext.state.notifications.data.nextToken)
              }
            />
          </Box>
        )}
      </Box>
    );
  };

  const NotificationEmptyList = (props) => {
    return (
      <Box
        className="box-default bg-black-t50 shadow-default"
        sx={{
          pt: configEntzy.APP_SPACING_LG,
          pb: configEntzy.APP_SPACING_LG,
          pl: configEntzy.APP_SPACING_MD,
          pr: configEntzy.APP_SPACING_MD,
          borderRadius: configEntzy.BORDER_SIZE_LG,
          color: "white",
        }}
      >
        <Container maxWidth="xs">
          <Box className="box-default" hidden={props.category !== "messages"}>
            <Typography variant="h1" noWrap={true}>
              <FontAwesomeIcon icon={iconMessages} fixedWidth />
            </Typography>
            <Typography
              variant="subtitle2"
              noWrap={true}
              sx={{
                mt: configEntzy.APP_SPACING_MD,
              }}
            >
              No recent message alerts
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              sx={{
                mt: configEntzy.APP_SPACING_MD,
              }}
            >
              <em>
                New messages will appear here when you are subscribed to push
                notifications inside eventuator conversations
              </em>
            </Typography>
          </Box>

          <Box className="box-default" hidden={props.category !== "activity"}>
            <Typography variant="h1" noWrap={true}>
              <FontAwesomeIcon icon={iconActvity} fixedWidth />
            </Typography>
            <Typography
              variant="subtitle2"
              noWrap={true}
              sx={{
                mt: configEntzy.APP_SPACING_MD,
              }}
            >
              No recent activity alerts
            </Typography>
            <Typography
              variant="subtitle1"
              align="center"
              sx={{
                mt: configEntzy.APP_SPACING_MD,
              }}
            >
              <em>
                Status changes will appear here for eventuators you own or
                follow or if you have tickets in play
              </em>
            </Typography>
          </Box>
        </Container>
      </Box>
    );
  };

  const NavBar = (props) => {
    const IconDMs = () => {
      return (
        <FontAwesomeIcon
          icon={
            mainContext.state.memberMessaging.viewer.open
              ? iconClose
              : iconNewDM
          }
          className="fa-beat"
          style={{
            "--fa-animation-duration": "1.5s",
            "--fa-beat-scale": 1.1,
          }}
          transform="grow-4 right-2"
          fixedWidth
        />
      );
    };

    const IconEdit = () => {
      return (
        <FontAwesomeIcon
          icon={
            (tab === 0 && mainContext.state.viewer.trail.editingMessageFeed) ||
            (tab === 2 && mainContext.state.viewer.trail.editingActivityFeed)
              ? iconClose
              : iconEdit
          }
          className="fa-beat"
          style={{
            "--fa-animation-duration": "1.5s",
            "--fa-beat-scale": 1.1,
          }}
          transform="grow-4 right-2"
          fixedWidth
        />
      );
    };
    return (
      <Box
        className="box-default bg-black-t25"
        sx={{
          p: configEntzy.APP_SPACING_MD,
          borderRadius: configEntzy.BORDER_SIZE_XL,
          mb: configEntzy.APP_SPACING_MD2X,
        }}
      >
        <Box
          className="box-default"
          sx={{
            // p: configEntzy.APP_SPACING_SM,
            borderRadius: configEntzy.BORDER_SIZE_XL,
          }}
        >
          {/* <Box
            className="box-default"
            sx={{
              pb: configEntzy.APP_SPACING_MD,
            }}
          >
            <Typography variant="subtitle2" color="white">
              {props.text}
            </Typography>
          </Box> */}
          <Box
            className="box-default half-width"
            sx={{
              pr: configEntzy.APP_SPACING_SM,
            }}
          >
            <ActionBoxButton
              variant="contained"
              bgColor="dusk.main"
              color="white"
              size="small"
              type="button"
              onClick={() =>
                mainContext.setMemberMessageViewer({
                  open: true,
                })
              }
              text={
                mainContext.state.memberMessaging.viewer.open ? "Close" : "DMs"
              }
              textNoCasing={true}
              startIcon={<IconDMs />}
            />
          </Box>
          <Box
            className="box-default half-width"
            sx={{
              pl: configEntzy.APP_SPACING_SM,
            }}
          >
            <ActionBoxButton
              variant="contained"
              bgColor="dusk.main"
              color="white"
              size="small"
              type="button"
              disabled={props.count === 0}
              onClick={props.count === 0 ? undefined : handleEditing}
              text={
                (tab === 0 &&
                  mainContext.state.viewer.trail.editingMessageFeed) ||
                (tab === 2 &&
                  mainContext.state.viewer.trail.editingActivityFeed)
                  ? "Close"
                  : "Edit"
              }
              endIcon={<IconEdit />}
            />
          </Box>

          {/* 
          <Accordion
            expanded={managing}
            onChange={handleManaging}
            disableGutters={true}
            className="bg-white-t50"
            sx={{
              p: configEntzy.APP_SPACING_SM,
              backgroundColor: "transparent",
            }}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography variant="subtitle2">{props.text}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grow in={managing} timeout={500}>
                <Box
                  className="box-default"
                  sx={{
                    pb: configEntzy.APP_SPACING_MD,
                  }}
                >
                  <Box
                    className="box-default"
                    sx={{
                      pb: configEntzy.APP_SPACING_MD,
                    }}
                    hidden={!props.showOpenDMs}
                  >
                    <ActionBoxButton
                      variant="contained"
                      bgColor="primary.main"
                      color="black"
                      size="medium"
                      type="button"
                      onClick={() =>
                        mainContext.setMemberMessageViewer({
                          open: true,
                        })
                      }
                      text={
                        mainContext.state.memberMessaging.viewer.open
                          ? "Close"
                          : "OPEN DMs"
                      }
                      textNoCasing={true}
                      startIcon={
                        <FontAwesomeIcon
                          icon={
                            mainContext.state.memberMessaging.viewer.open
                              ? iconClose
                              : iconNewDM
                          }
                          className="fa-beat"
                          style={{
                            "--fa-animation-duration": "1.5s",
                            "--fa-beat-scale": 1.1,
                          }}
                          transform="grow-4 right-2"
                          fixedWidth
                        />
                      }
                    />
                  </Box>

                  {props.count > 0 ? (
                    <Box
                      className="box-default"
                      sx={{
                        pb: configEntzy.APP_SPACING_MD,
                      }}
                    >
                      <ActionBoxButton
                        variant="contained"
                        bgColor="secondary.main"
                        color="black"
                        size="medium"
                        type="button"
                        onClick={handleEditing}
                        text={
                          (tab === 0 &&
                            mainContext.state.viewer.trail
                              .editingMessageFeed) ||
                          (tab === 2 &&
                            mainContext.state.viewer.trail.editingActivityFeed)
                            ? "Close"
                            : "Edit Feed"
                        }
                        startIcon={
                          <FontAwesomeIcon
                            icon={
                              (tab === 0 &&
                                mainContext.state.viewer.trail
                                  .editingMessageFeed) ||
                              (tab === 2 &&
                                mainContext.state.viewer.trail
                                  .editingActivityFeed)
                                ? iconClose
                                : iconEdit
                            }
                            className="fa-beat"
                            style={{
                              "--fa-animation-duration": "1.5s",
                              "--fa-beat-scale": 1.1,
                            }}
                            transform="grow-4 right-2"
                            fixedWidth
                          />
                        }
                      />
                    </Box>
                  ) : (
                    <Box
                      className="box-default bg-black-t25 shadow-light"
                      sx={{
                        p: configEntzy.APP_SPACING_MD,
                      }}
                    >
                      <Typography variant="subtitle1" noWrap={true}>
                        <em>
                          <span>
                            No active alerts
                          </span>
                        </em>
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Grow>
            </AccordionDetails>
          </Accordion> */}
        </Box>
      </Box>
    );
  };

  return (
    <Box className="box-default">
      <Container maxWidth="lg" disableGutters>
        <Box className="box-default">
          <Box
            className="box-default bg-black-t25"
            sx={{
              borderRadius: configEntzy.BORDER_SIZE_XL,
              p: configEntzy.APP_SPACING_MD,
            }}
          >
            <Tabs
              value={tab}
              onChange={handleTabs}
              variant="fullWidth"
              aria-label="Notfications"
              sx={{ ".MuiTabs-indicator": { display: "none" } }}
            >
              {[
                {
                  id: "messages",
                  icon: iconMessages,
                  count:
                    mainContext.state.notifications.data.messagesUnreadCount,
                },
                { id: "eventuating", icon: iconEventuators, count: 0 },
                {
                  id: "activity",
                  icon: iconActvity,
                  count:
                    mainContext.state.notifications.data.activityUnreadCount,
                },
              ].map((item, index) => (
                <Tab
                  key={item.id}
                  label={tabLabel(item, index)}
                  {...tabProps(index)}
                  wrapped={true}
                  sx={{
                    fontSize: configEntzy.FONT_SCALE_LG,
                    p: 0,
                  }}
                />
              ))}
            </Tabs>
          </Box>
          <TabPanel value={tab} index={0}>
            {mainContext.state.notifications.hydrated && (
              <Box
                className="box-default"
                sx={{ pt: configEntzy.APP_SPACING_MD }}
              >
                <NavBar
                  text="Your Message Notifications"
                  count={mainContext.state.notifications.data.messagesCount}
                  position="left"
                />
                <Box
                  className="box-default bg-black-t25"
                  sx={{
                    p: configEntzy.APP_SPACING_MD,
                    borderRadius: configEntzy.BORDER_SIZE_XL,
                  }}
                >
                  {mainContext.state.notifications.data.messagesCount > 0 ? (
                    <NotificationList
                      notifications={
                        mainContext.state.notifications.data.messages
                      }
                    />
                  ) : (
                    <NotificationEmptyList category="messages" />
                  )}
                </Box>
              </Box>
            )}
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Box
              className="box-default bg-black-t25"
              sx={{
                mt: configEntzy.APP_SPACING_MD,
                p: configEntzy.APP_SPACING_MD,
                borderRadius: configEntzy.BORDER_SIZE_XL,
              }}
            >
              <Box
                className="box-default bg-black-t50 action-pointer"
                sx={{
                  borderRadius: configEntzy.BORDER_SIZE_XL,
                  color: "white",
                }}
              >
                <Box
                  className={
                    "box-default half-width text-left" +
                    (calendarView ? " bg-white-t25" : " bg-black-t75")
                  }
                  sx={{
                    p: configEntzy.APP_SPACING_MD,
                    // borderRight: "1px solid #555",
                    borderTopLeftRadius: configEntzy.BORDER_SIZE_LG,
                    borderBottomLeftRadius: configEntzy.BORDER_SIZE_LG,
                    borderTopRightRadius: configEntzy.BORDER_SIZE_MD,
                    borderBottomRightRadius: configEntzy.BORDER_SIZE_MD,
                  }}
                  onClick={() => handleCalendarView(true)}
                >
                  <Typography variant="subtitle2">
                    {calendarView && (
                      <span>
                        <FontAwesomeIcon
                          icon={iconCalendar}
                          color="orange"
                          fixedWidth
                        />
                        <span>&nbsp;&nbsp;</span>
                      </span>
                    )}
                    Calendar
                  </Typography>
                </Box>
                <Box
                  className={
                    "box-default half-width text-right" +
                    (calendarView ? " bg-black-t75" : " bg-white-t25")
                  }
                  sx={{
                    p: configEntzy.APP_SPACING_MD,
                    borderTopRightRadius: configEntzy.BORDER_SIZE_LG,
                    borderBottomRightRadius: configEntzy.BORDER_SIZE_LG,
                    borderTopLeftRadius: configEntzy.BORDER_SIZE_MD,
                    borderBottomLeftRadius: configEntzy.BORDER_SIZE_MD,
                  }}
                  onClick={() => handleCalendarView(false)}
                >
                  <Typography variant="subtitle2">
                    Eventuators
                    {!calendarView && (
                      <span>
                        <span>&nbsp;&nbsp;</span>
                        <span className="fa-layers fa-fw">
                          <FontAwesomeIcon icon={iconSquare} color="orange" />
                          <FontAwesomeIcon
                            icon={iconEventSolid}
                            color="black"
                            transform="shrink-8"
                          />
                        </span>
                      </span>
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box
              className="box-default"
              sx={{ pt: configEntzy.APP_SPACING_MD }}
            >
              <EventIndex
                {...props}
                page="/calendar"
                calendarView={calendarView}
                handleCalendarView={handleCalendarView}
              />
              {!calendarView && <EventIndex {...props} page="/eventuators" />}
            </Box>
          </TabPanel>
          <TabPanel value={tab} index={2}>
            {mainContext.state.notifications.hydrated && (
              <Box
                className="box-default"
                sx={{ pt: configEntzy.APP_SPACING_MD }}
              >
                <NavBar
                  text="Your Activity Notifications"
                  count={mainContext.state.notifications.data.activityCount}
                  position="right"
                />
                <Box
                  className="box-default bg-black-t25"
                  sx={{
                    p: configEntzy.APP_SPACING_MD,
                    borderRadius: configEntzy.BORDER_SIZE_XL,
                  }}
                >
                  {mainContext.state.notifications.data.activityCount > 0 ? (
                    <NotificationList
                      notifications={
                        mainContext.state.notifications.data.activity
                      }
                    />
                  ) : (
                    <NotificationEmptyList category="activity" />
                  )}
                </Box>
              </Box>
            )}
          </TabPanel>
        </Box>
      </Container>
    </Box>
  );
}

export default WidgetHome;
