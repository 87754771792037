// react core
import { useContext, useState } from "react";

// material tabs
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// material accordion
// import Accordion from "@mui/material/Accordion";
// import AccordionDetails from "@mui/material/AccordionDetails";
// import AccordionSummary from "@mui/material/AccordionSummary";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// material design
import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Zoom from "@mui/material/Zoom";
import Drawer from "@mui/material/Drawer";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

// fonts icons and colors
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAtomAlt as iconEventuators } from "@fortawesome/pro-thin-svg-icons";
// import { faSignIn as iconConnect } from "@fortawesome/pro-thin-svg-icons";
import { faWrench as iconEdit } from "@fortawesome/pro-thin-svg-icons";
import { faPlus as iconNewRunner } from "@fortawesome/pro-thin-svg-icons";
import { faPortalEnter as iconNewRider } from "@fortawesome/pro-thin-svg-icons";
import { faClose as iconClose } from "@fortawesome/pro-thin-svg-icons";
import { faMoneyBillTrendUp as iconFinancials } from "@fortawesome/pro-duotone-svg-icons";
import { faEllipsisV as iconDropMenu } from "@fortawesome/pro-solid-svg-icons";

// import { faCog as iconManage } from "@fortawesome/pro-thin-svg-icons";
// import { faStream as iconEditLayer } from "@fortawesome/pro-thin-svg-icons";
// import { faAtomAlt as iconNewRunnerLayer } from "@fortawesome/pro-thin-svg-icons";
// import { faAtomAlt as iconNewRiderLayer } from "@fortawesome/pro-thin-svg-icons";
// import { faExplosion as iconNew } from "@fortawesome/pro-duotone-svg-icons";
// import { blueGrey } from "@mui/material/colors/";

// entzy config and components
import configEntzy from "components/config/ConfigEntzy";
import InputForm from "components/input/InputForm";
import WidgetHomeMenu from "components/event/widgets/WidgetHomeMenu";
import MainCallbackLoader from "components/main/MainCallbackLoader";

// entzy context and services
import { MainContext } from "components/main/MainContext";
import { EventContext } from "pages/events/EventContext";
import { ActionLoader } from "components/utils/common/CommonLoaders";
import {
  BigButton,
  ActionBoxButton,
  EventButton,
  MoreButton,
} from "components/utils/common/CommonButtons";

function EventList(props) {
  const user = props.user;
  const mainContext = useContext(MainContext);
  const eventContext = useContext(EventContext);
  const [managing, setManaging] = useState(
    mainContext.state.viewer.trail.editingMessageFeed
      ? true
      : mainContext.state.viewer.events.active.length === 0
      ? true
      : false
  );
  const [loading, setLoading] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const [archiving, setArchiving] = useState(null);
  const [showCreate, setShowCreate] = useState(false);
  const [showJoin, setShowJoin] = useState(false);
  const [tab, setTab] = useState(
    !isNaN(mainContext.state.viewer.trail.joinSubTab)
      ? mainContext.state.viewer.trail.joinSubTab
      : 0
  );

  // component functions
  const isEventArchived = (event) => {
    return event.Details && event.Details.includes("archived");
  };
  const isViewerEventOwner = (event) => {
    return event.Details && event.Details.includes("owner");
  };
  const isViewerEventManager = (event) => {
    return event.Details && event.Details.includes("manage");
  };

  const handleLoading = (value) => {
    setLoading(value);
  };

  const handleCreateCallback = async (data) => {
    const eventName = data
      .filter((obj) => obj.id === "event-name")[0]
      .value.toLowerCase();
    const eventCountry = data.filter((obj) => obj.id === "event-country")[0]
      .value;
    const eventCurrency = data.filter((obj) => obj.id === "event-currency")[0]
      .value;
    if (eventName && eventName.length && eventCountry && eventCurrency) {
      const eventContent = await eventContext.prepareCreateEvent({
        name: eventName,
        country: eventCountry.code.short,
        currency: eventCurrency.code.long,
      });
      if (eventContent.alert) {
        return {
          alert: true,
          message: eventContent.message,
        };
      } else {
        handleLoading(true);
        const element = document.getElementById("anchor-main-app");
        if (element) {
          eventContext.state.toolbox.scrollIntoViewIfNeeded(element, true);
        }
        const startData = await eventContext.prepareStartEvent(
          eventContent.data
        );
        const startPayload = {
          startEvent: true,
          params: [eventContent, startData, "create"],
        };
        // update main context for change in event list and to track source info
        // mainContext.pullViewerEvents({ data: eventContent.data, more: true });
        mainContext.addEvent(eventContent.data, true, true);
        mainContext.updateViewerTrail({
          sourcePage: "home",
          joinTab: 1,
          joinSubTab: tab,
        });
        mainContext.selectMenu("e");
        // navigate to event runner page with start callback payload
        props.navigate(eventContext.state.constants.pointers.main + eventName, {
          state: startPayload,
        });
        return { alert: false };
      }
    } else {
      return {
        alert: true,
        message: "Event name, launch country and ticket currency required",
      };
    }
  };
  const handleJoinCallback = async (data, loader) => {
    const eventName = data
      .filter((obj) => obj.id === "event-name")[0]
      .value.toLowerCase();
    if (eventName && eventName.length) {
      const element = document.getElementById("anchor-main-app");
      if (element) {
        eventContext.state.toolbox.scrollIntoViewIfNeeded(element, true);
      }
      if (loader) {
        handleLoading(true);
      }
      const eventUrl = eventContext.state.toolbox.parseEntzyUrl(eventName);
      const eventContent = await eventContext.prepareJoinEvent({
        name: eventUrl,
      });
      if (eventContent.alert) {
        if (loader) {
          handleLoading(false);
          mainContext.updateAlert({
            show: true,
            message: eventContent.message,
          });
        }
        return eventContent;
      }
      const startData = await eventContext.prepareStartEvent(eventContent.data);
      const startPayload = {
        startEvent: true,
        params: [eventContent, startData, "join"],
      };
      // navigate to event runner page with start callback payload
      mainContext.updateViewerTrail({
        sourcePage: "home",
        joinTab: 1,
        joinSubTab: tab,
      });
      mainContext.selectMenu("e");
      props.navigate(eventContext.state.constants.pointers.main + eventUrl, {
        state: startPayload,
      });
      return { alert: false };
    } else {
      return {
        alert: true,
        message: "Event name is required",
      };
    }
  };

  const handleArchiveEvent = async (event) => {
    const update = {
      EventId: event.EventId,
      Url: event.Url,
      unarchive: isEventArchived(event) ? true : false,
    };
    setArchiving(update);
    const response = await mainContext.prepareArchiveEvent(update);
    if (response.alert) {
      setArchiving(response);
      return response;
    } else {
      mainContext.archiveEvent(response);
      setArchiving(null);
      return { alert: false };
    }
  };
  const handleRemoveEvent = async (event) => {
    const update = {
      EventId: event.EventId,
      Url: event.Url,
      owner: isViewerEventOwner(event),
      manager: isViewerEventManager(event),
    };
    setArchiving(update);

    const response = await eventContext.preparePinEvent({
      EventId: event.EventId,
      pinned: false,
    });
    if (response.alert) {
      setArchiving(response);
      mainContext.updateAlert(response);
      return response;
    } else {
      setArchiving(null);
      mainContext.removeEvent({ EventId: event.EventId });
      setLoading(false);
      return { alert: false };
    }

    // const response = await mainContext.prepareRemoveEvent(update);
    // if (response.alert) {
    //   setArchiving(response);
    //   return response;
    // } else {
    //   setArchiving(null);
    //   mainContext.removeEvent(response);
    //   return { alert: false };
    // }
  };

  const handleManaging = async () => {
    setManaging(!managing);
  };
  const handleEditing = async () => {
    mainContext.updateViewerTrail({
      editingMessageFeed: !mainContext.state.viewer.trail.editingMessageFeed,
    });
  };
  const handleShowCreate = async () => {
    setShowCreate(!showCreate);
    setShowJoin(false);
  };
  const handleShowJoin = async () => {
    setShowJoin(!showJoin);
    setShowCreate(false);
  };
  const handlePinnedEventClick = async (id, url) => {
    return handleJoinCallback([{ id: "event-name", value: url }], true);
  };

  const handleTabs = (event, newTab) => {
    setTab(newTab);
  };
  function TabPanel(props) {
    const { children, value: tab, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={tab !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {tab === index && (
          <Box className="box-default" sx={{ pt: configEntzy.APP_SPACING_MD }}>
            {children}
          </Box>
        )}
      </div>
    );
  }
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  function tabProps(index) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  }
  function tabLabel(index, count) {
    return (
      <Box
        className="box-default"
        sx={{
          pb: configEntzy.APP_SPACING_SM,
        }}
      >
        <Typography
          variant="subtitle2"
          color={tab === index ? "primary" : "white"}
        >
          <span>
            <span>
              {index === 2 ? "Riding" : index === 1 ? "Running" : "All"}
            </span>
            <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <span
              className={
                (count > 0 ? "bg-black" : "bg-white") +
                " shadow-default rounded"
              }
              style={{
                color: count > 0 ? "white" : "black",
              }}
            >
              <span>&nbsp;</span>
              {count}
              <span>&nbsp;</span>
            </span>
          </span>
        </Typography>
        {/* <Typography variant="body2" color="primary">
          Events {index === 2 ? "you Ride" : index === 1 ? "you Run" : ""}
        </Typography> */}
      </Box>
    );
  }

  const handlePullMore = async () => {
    setLoadingMore(true);
    const response = await mainContext.preparePullViewerEvents({
      nextToken: mainContext.state.viewer.events.nextToken,
    });
    if (response.alert) {
      mainContext.updateAlert(response);
    } else {
      mainContext.pullViewerEvents(response);
    }
    setLoadingMore(false);
  };

  const handleOpenTransactions = () => {
    mainContext.setMemberMessageViewer({
      open: false,
    });
    mainContext.setMemberTransactionViewer({
      open: mainContext.state.memberTransactions.viewer.open ? false : true,
    });
  };

  const events =
    tab === 2
      ? mainContext.state.viewer.events.riders.concat(
          mainContext.state.viewer.events.archived.filter(
            (obj) => obj.Details === "archived"
          )
        )
      : tab === 1
      ? mainContext.state.viewer.events.runners.concat(
          mainContext.state.viewer.events.archived.filter((obj) =>
            ["owner-archived", "manager-archived"].includes(obj.Details)
          )
        )
      : mainContext.state.viewer.events.active.concat(
          mainContext.state.viewer.events.archived
        );

  const renderCreateForm = () => {
    return (
      <Box className="box-default">
        <Drawer anchor="bottom" open={showCreate} onClose={handleShowCreate}>
          <Box
            className="box-default"
            sx={{
              mb: configEntzy.APP_SPACING_LG,
              p: configEntzy.APP_SPACING_MD,
            }}
            // hidden={!showCreate}
          >
            <Box
              className="box-default text-right"
              sx={{
                pt: configEntzy.APP_SPACING_MD2X,
                pr: configEntzy.APP_SPACING_MD2X,
              }}
            >
              <Typography variant="h3">
                <FontAwesomeIcon
                  icon={iconClose}
                  className="action-pointer"
                  onClick={handleShowCreate}
                />
              </Typography>
            </Box>
            <Box
              className="box-default"
              sx={{
                pt: configEntzy.APP_SPACING_LG,
                pb: configEntzy.APP_SPACING_MD,
              }}
            >
              <Typography variant="subtitle2">
                <span className="fa-layers fa-fw fa-3x">
                  <FontAwesomeIcon
                    icon={iconNewRunner}
                    transform="up-8 left-20"
                    style={{ color: "orange" }}
                  />
                  <FontAwesomeIcon icon={iconEventuators} transform="up-8" />
                </span>
              </Typography>
              <Typography
                variant="subtitle2"
                color="primary"
                className="upper-case"
                sx={{
                  mt: configEntzy.APP_SPACING_MD,
                }}
              >
                New Eventuator
              </Typography>
            </Box>
            <Box
              className="box-default"
              sx={{
                pb: configEntzy.APP_SPACING_LG,
              }}
            >
              <Typography variant="subtitle1">
                Let's get going with the basics
              </Typography>
              <Typography variant="subtitle1" color="primary">
                Enter a URL, launch country and ticket currency
              </Typography>
            </Box>
            <Container maxWidth="xs">
              <InputForm
                navigate={props.navigate}
                fields={[
                  {
                    id: "event-name",
                    type: "text-standard",
                    label: "Event URL",
                    value: "",
                    alphanumeric: true,
                    required: true,
                    maxLength:
                      eventContext.state.constants.events.maxNameLength,
                    startAdornment: eventContext.state.constants.pointers.main,
                  },
                  {
                    id: "event-country",
                    type: "selector-country",
                    label: "Country where launches will be held",
                    value: null,
                    required: true,
                  },
                  {
                    id: "event-currency",
                    type: "selector-currency",
                    label: "Currency of ticket sales",
                    value: null,
                    required: true,
                  },
                ]}
                submitText="Create"
                callback={handleCreateCallback}
                // cancel={handleShowCreate}
                // cancelText="Cancel"
                footNotes={[
                  "These 3 settings cannot be changed after creation.",
                  "Select worldwide if your launches will be multi-country.",
                ]}
              />
            </Container>
          </Box>
        </Drawer>
      </Box>
    );
  };

  const renderJoinForm = () => {
    return (
      <Drawer anchor="bottom" open={showJoin} onClose={handleShowJoin}>
        <Box
          className="box-default"
          sx={{
            mb: configEntzy.APP_SPACING_HL,
            p: configEntzy.APP_SPACING_MD,
          }}
        >
          <Box
            className="box-default text-right"
            sx={{
              pt: configEntzy.APP_SPACING_MD2X,
              pr: configEntzy.APP_SPACING_MD2X,
            }}
          >
            <Typography variant="h3">
              <FontAwesomeIcon
                icon={iconClose}
                className="action-pointer"
                onClick={handleShowJoin}
              />
            </Typography>
          </Box>
          <Box
            className="box-default"
            sx={{
              pt: configEntzy.APP_SPACING_LG,
              pb: configEntzy.APP_SPACING_MD,
            }}
          >
            <Typography variant="subtitle2">
              <span className="fa-layers fa-fw fa-3x">
                <FontAwesomeIcon
                  icon={iconNewRider}
                  transform="up-8 left-20"
                  style={{ color: "orange" }}
                />
                <FontAwesomeIcon icon={iconEventuators} transform="up-8" />
              </span>
            </Typography>
            <Typography
              variant="subtitle2"
              color="primary"
              className="upper-case"
            >
              Join Eventuator
            </Typography>
          </Box>
          <Box
            className="box-default"
            sx={{
              pb: configEntzy.APP_SPACING_LG,
            }}
          >
            <Typography variant="subtitle1">
              Looking for a ticket to ride?
            </Typography>
            <Typography variant="subtitle1" color="primary">
              Enter a URL to join in-play activity
            </Typography>
          </Box>
          <Container maxWidth="xs">
            <InputForm
              navigate={props.navigate}
              fields={[
                {
                  id: "event-name",
                  type: "text-standard",
                  placeholder: "Enter event name or URL to jump in",
                  value: "",
                  maxLength:
                    eventContext.state.constants.events.maxNameLength + 50, // allow for URL
                  required: true,
                  alignCenter: true,
                  startAdornment: eventContext.state.constants.pointers.main,
                  endAdornment: <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>,
                },
              ]}
              submitText="Go"
              // submitType="focus"
              callback={handleJoinCallback}
            />
          </Container>
        </Box>
      </Drawer>
    );
  };

  const RenderNavBar = () => {
    return (
      <Box className="box-default">
        <Box
          className="box-default bg-black-t50 shadow-default"
          sx={{
            mb: configEntzy.APP_SPACING_MD,
            p: configEntzy.APP_SPACING_SM,
            borderRadius: configEntzy.BORDER_SIZE_LG,
          }}
        >
          <Box
            className="box-default text-left"
            sx={{
              width: "64%",
              p: configEntzy.APP_SPACING_MD,
            }}
          >
            <Typography variant="subtitle2" color="white">
              {tab === 2
                ? "Eventuators you Ride"
                : tab === 1
                ? "Eventuators you Run"
                : "Your Eventuators"}
            </Typography>
          </Box>
          <Box
            className="box-default text-right action-pointover"
            sx={{
              width: "12%",
              p: configEntzy.APP_SPACING_MD,
            }}
            onClick={handleShowCreate}
            hidden={managing}
          >
            <Typography variant="subtitle2" color="white">
              <FontAwesomeIcon
                icon={iconNewRunner}
                transform="grow-8"
                fixedWidth
              />
            </Typography>
          </Box>
          <Box
            className="box-default text-right action-pointover"
            sx={{
              width: "12%",
              p: configEntzy.APP_SPACING_MD,
            }}
            onClick={handleShowJoin}
            hidden={managing}
          >
            <Typography variant="subtitle2" color="white">
              <FontAwesomeIcon
                icon={iconNewRider}
                transform="grow-8"
                fixedWidth
              />
            </Typography>
          </Box>
          {/* <Box
            className="box-default text-right action-pointover"
            sx={{
              width: "12%",
              p: configEntzy.APP_SPACING_MD,
            }}
            onClick={handleOpenTransactions}
            hidden={managing}
          >
            <Typography variant="subtitle2" color="white">
              <FontAwesomeIcon
                icon={iconFinancials}
                transform="grow-8"
                fixedWidth
              />
            </Typography>
          </Box> */}
          <Box
            className="box-default text-right action-pointover"
            sx={{
              width: managing ? "36%" : "12%",
              p: configEntzy.APP_SPACING_MD,
            }}
            onClick={handleManaging}
          >
            <Typography variant="subtitle2" color="white">
              <FontAwesomeIcon
                icon={managing ? iconClose : iconDropMenu}
                transform="grow-8"
                fixedWidth
              />
            </Typography>
          </Box>
        </Box>
        <Box
          className="box-default bg-black-t50 shadow-default"
          sx={{
            mb: configEntzy.APP_SPACING_MD,
            p: configEntzy.APP_SPACING_MD,
            borderRadius: configEntzy.BORDER_SIZE_LG,
          }}
          hidden={!managing}
        >
          <Zoom in={managing}>
            {/* <Accordion
            expanded={managing}
            onChange={handleManaging}
            disableGutters={true}
            sx={{
              p: configEntzy.APP_SPACING_SM,
              backgroundColor: "transparent",
            }}
          > */}
            {/* <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography
                variant="subtitle2"
                noWrap={true}
                color="white"
                sx={{ width: "60%", textAlign: "left" }}
              >
                {tab === 2
                  ? "Eventuators you Ride"
                  : tab === 1
                  ? "Eventuators you Run"
                  : "Your Eventuators"}
              </Typography>
              <Typography
                variant="subtitle2"
                noWrap={true}
                sx={{ width: "40%", textAlign: "right" }}
              >
                <TextTag
                  text={<span>&nbsp;Manage Feed&nbsp;</span>}
                  bgColor="primary.main"
                  color="black"
                  rounded={true}
                />
                <span>&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </Typography>
            </AccordionSummary> */}
            {/* <AccordionDetails> */}
            <Box className="box-default">
              <Box
                className="box-default"
                sx={{
                  p: configEntzy.APP_SPACING_SM,
                }}
              >
                <ActionBoxButton
                  variant="contained"
                  bgColor="primary.main"
                  color="black"
                  size="medium"
                  type="button"
                  onClick={handleShowCreate}
                  text={showCreate ? "Close" : "New Eventuator"}
                  startIcon={
                    <FontAwesomeIcon
                      icon={showCreate ? iconClose : iconNewRunner}
                      className="fa-beat"
                      style={{
                        "--fa-animation-duration": "1.5s",
                        "--fa-beat-scale": 1.1,
                      }}
                      transform="grow-4 right-2"
                      fixedWidth
                    />
                  }
                />
              </Box>
              <Box
                className="box-default"
                sx={{
                  p: configEntzy.APP_SPACING_SM,
                }}
              >
                <ActionBoxButton
                  variant="contained"
                  bgColor="primary.main"
                  color="black"
                  size="medium"
                  type="button"
                  onClick={handleShowJoin}
                  text={showCreate ? "Close" : "Join Eventuator"}
                  startIcon={
                    <FontAwesomeIcon
                      icon={showCreate ? iconClose : iconNewRider}
                      className="fa-beat"
                      style={{
                        "--fa-animation-duration": "1.5s",
                        "--fa-beat-scale": 1.1,
                      }}
                      transform="grow-4 right-2"
                      fixedWidth
                    />
                  }
                />
              </Box>
              <Box
                className="box-default"
                sx={{
                  p: configEntzy.APP_SPACING_SM,
                }}
              >
                <ActionBoxButton
                  variant="contained"
                  bgColor="secondary.main"
                  color="black"
                  size="medium"
                  type="button"
                  onClick={handleOpenTransactions}
                  text={
                    mainContext.state.memberTransactions.viewer.open
                      ? "Close"
                      : "Open Financials"
                  }
                  startIcon={
                    <FontAwesomeIcon
                      icon={
                        mainContext.state.memberTransactions.viewer.open
                          ? iconClose
                          : iconFinancials
                      }
                      className="fa-beat"
                      style={{
                        "--fa-animation-duration": "1.5s",
                        "--fa-beat-scale": 1.1,
                      }}
                      transform="grow-4 right-2"
                      fixedWidth
                    />
                  }
                />
              </Box>
              {events.length > 0 && (
                <Box
                  className="box-default"
                  sx={{
                    p: configEntzy.APP_SPACING_SM,
                  }}
                >
                  <ActionBoxButton
                    variant="contained"
                    bgColor="secondary.main"
                    color="black"
                    size="medium"
                    type="button"
                    onClick={handleEditing}
                    text={
                      mainContext.state.viewer.trail.editingMessageFeed
                        ? "Close"
                        : "Edit Feed"
                    }
                    startIcon={
                      <FontAwesomeIcon
                        icon={
                          mainContext.state.viewer.trail.editingMessageFeed
                            ? iconClose
                            : iconEdit
                        }
                        className="fa-beat"
                        style={{
                          "--fa-animation-duration": "1.5s",
                          "--fa-beat-scale": 1.1,
                        }}
                        transform="grow-4 right-2"
                        fixedWidth
                      />
                    }
                  />
                </Box>
              )}
            </Box>
            {/* </AccordionDetails>
          </Accordion> */}
          </Zoom>
        </Box>
      </Box>
    );
  };

  const EventuateData = (props) => {
    return (
      <Box
        className="box-default"
        sx={{
          pt: configEntzy.APP_SPACING_MD,
        }}
      >
        <RenderNavBar />
        <Box className="box-default">
          {events.length === 0 ? (
            <Box
              className="box-default bg-black-t25 shadow-default"
              sx={{
                pt: configEntzy.APP_SPACING_LG,
                pb: configEntzy.APP_SPACING_LG,
                borderRadius: configEntzy.BORDER_SIZE_LG,
              }}
            >
              <Typography variant="h1" noWrap={true}>
                <FontAwesomeIcon icon={iconEventuators} fixedWidth />
              </Typography>
              <Typography
                variant="subtitle2"
                sx={{
                  mt: configEntzy.APP_SPACING_MD,
                }}
              >
                Nothing in your feed yet
              </Typography>
              <Typography
                variant="body1"
                sx={{ mt: configEntzy.APP_SPACING_MD }}
              >
                <em>
                  {tab === 2
                    ? "Eventuators you ride will appear here"
                    : tab === 1
                    ? "Eventuators you run will appear here"
                    : "Your eventuator activity will appear here"}
                </em>
              </Typography>
              <Typography variant="body1">
                <em>
                  {tab === 2
                    ? "Join one with a link to get started"
                    : tab === 1
                    ? "Create one to get started"
                    : "Create or join to get started"}
                </em>
              </Typography>
              {/* {tab === 2 && (
              <Fragment>
                <Typography variant="body1">
                  <em>Your feed will auto track ticket activity</em>
                </Typography>
              </Fragment>
            )} */}
            </Box>
          ) : (
            <Box className="box-default">
              {mainContext.state.viewer.trail.editingMessageFeed && (
                <Box
                  className="box-default"
                  sx={{
                    mb: configEntzy.APP_SPACING_MD2X,
                  }}
                >
                  <Typography variant="body1">
                    <em>Archiving items moves to the end of your feed</em>
                  </Typography>
                  <Typography variant="body1">
                    <em>Removing items unpins & clears from your feed</em>
                  </Typography>
                </Box>
              )}
              {events.map((event) => {
                return (
                  <Box
                    key={event.EventId}
                    className="box-default"
                    sx={{
                      mb:
                        events.indexOf(event) === events.length - 1
                          ? 0
                          : configEntzy.APP_SPACING_MD,
                    }}
                  >
                    <EventButton
                      user={user}
                      event={event}
                      mainContext={mainContext}
                      size="sm"
                      onClick={handlePinnedEventClick}
                      messageCount={
                        mainContext.state.notifications.data.messages.filter(
                          (obj) =>
                            obj.MessageUrl.endsWith(
                              configEntzy.URL_POINTERS.MAIN + event.Url
                            ) && !obj.MessageRead
                        ).length
                      }
                      activityCount={
                        mainContext.state.notifications.data.activity.filter(
                          (obj) =>
                            obj.MessageUrl.endsWith(
                              configEntzy.URL_POINTERS.MAIN + event.Url
                            ) && !obj.MessageRead
                        ).length
                      }
                      editing={
                        mainContext.state.viewer.trail.editingMessageFeed
                      }
                      archiving={archiving}
                      isEventArchived={isEventArchived}
                      handleArchiveEvent={handleArchiveEvent}
                      handleRemoveEvent={handleRemoveEvent}
                    />
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      </Box>
    );
  };

  // add effect to remember tab choice
  // useEffect(() => {
  //   if (mainContext.state.viewer.trail.joinSubTab) {
  //     setTab(mainContext.state.viewer.trail.joinSubTab);
  //   }
  // }, [mainContext.state.viewer.trail.joinSubTab]);

  return (
    <Box
      className={"box-default" + (props.user.connected ? " bg-black-t25" : "")}
      sx={{
        pt: configEntzy.APP_SPACING_MD,
        pl: configEntzy.APP_SPACING_MD,
        pr: configEntzy.APP_SPACING_MD,
        borderRadius: configEntzy.BORDER_SIZE_XL,
        mt: props.user.connected ? configEntzy.APP_SPACING_MD : 0,
        mb: configEntzy.APP_SPACING_HL,
      }}
    >
      {loading ? (
        <Box
          className="box-default"
          sx={{
            pt: configEntzy.APP_SPACING_HL,
            pb: configEntzy.APP_SPACING_HL,
          }}
        >
          <ActionLoader />
        </Box>
      ) : props.user.connected ? (
        <Box
          className="box-default bg-white-t25"
          sx={{
            mb: configEntzy.APP_SPACING_MD,
            p: configEntzy.APP_SPACING_MD,
            borderRadius: configEntzy.BORDER_SIZE_LG,
            color: "white",
          }}
        >
          <Container maxWidth="md" disableGutters>
            {mainContext.state.viewer.events.hydrated && (
              <Box className="box-default">
                <Box className="box-default">
                  <Box className="box-default">{renderCreateForm()}</Box>
                  <Box className="box-default" hidden={showCreate}>
                    {renderJoinForm()}
                  </Box>

                  {/* <CounterAvatar
                  size="sm"
                  count={mainContext.state.viewer.events.active.length}
                /> */}
                </Box>
                <Box className="box-default">
                  <Box className="box-default">
                    <Tabs
                      value={tab}
                      onChange={handleTabs}
                      indicatorColor="primary"
                      textColor="inherit"
                      variant="fullWidth"
                      aria-label="Pinned Events"
                    >
                      <Tab
                        label={tabLabel(
                          0,
                          mainContext.state.viewer.events.active.length
                        )}
                        {...tabProps(0)}
                        wrapped={true}
                        sx={{
                          fontSize: configEntzy.FONT_SCALE_LG,
                          pt: configEntzy.APP_SPACING_MD,
                          pb: configEntzy.APP_SPACING_MD,
                        }}
                      />
                      <Tab
                        label={tabLabel(
                          1,
                          mainContext.state.viewer.events.runners.length
                        )}
                        {...tabProps(1)}
                        sx={{
                          fontSize: configEntzy.FONT_SCALE_LG,
                          pt: configEntzy.APP_SPACING_MD,
                          pb: configEntzy.APP_SPACING_MD,
                        }}
                      />
                      <Tab
                        label={tabLabel(
                          2,
                          mainContext.state.viewer.events.riders.length
                        )}
                        {...tabProps(2)}
                        sx={{
                          fontSize: configEntzy.FONT_SCALE_LG,
                          pt: configEntzy.APP_SPACING_MD,
                          pb: configEntzy.APP_SPACING_MD,
                        }}
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={tab} index={0}>
                    <EventuateData {...props} />
                  </TabPanel>
                  <TabPanel value={tab} index={1}>
                    <EventuateData {...props} />
                  </TabPanel>
                  <TabPanel value={tab} index={2}>
                    <EventuateData {...props} />
                  </TabPanel>
                </Box>

                {mainContext.state.viewer.events.nextToken && (
                  <Box
                    className="box-default"
                    sx={{
                      pt: configEntzy.APP_SPACING_LG,
                      pb: configEntzy.APP_SPACING_LG,
                    }}
                  >
                    <MoreButton
                      variant="contained"
                      size="small"
                      type="button"
                      color="bright"
                      text="More"
                      loading={loadingMore}
                      onClick={handlePullMore}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Container>
        </Box>
      ) : (
        <Box className="box-default">
          <Container maxWidth="md" disableGutters>
            <Fade in={true} timeout={1000}>
              <Box
                className="box-default bg-black-t75"
                sx={{
                  p: configEntzy.APP_SPACING_MD,
                  borderRadius: configEntzy.BORDER_SIZE_XL,
                }}
              >
                <Box
                  className="box-default"
                  sx={{
                    pt: configEntzy.APP_SPACING_LG,
                  }}
                >
                  <Typography variant="h1">
                    <FontAwesomeIcon icon={iconEventuators} size="2x" />
                  </Typography>
                </Box>
                <Box
                  className="box-default"
                  sx={{
                    pt: configEntzy.APP_SPACING_LG,
                    pb: configEntzy.APP_SPACING_LG,
                    pl: configEntzy.APP_SPACING_MD,
                    pr: configEntzy.APP_SPACING_MD,
                  }}
                >
                  <Container maxWidth="xs" disableGutters>
                    <Typography variant="h2" color="orange">
                      Entzy Eventuators.
                    </Typography>
                    <Typography
                      variant="h2"
                      color="orange"
                      sx={{
                        mt: configEntzy.APP_SPACING_SM,
                      }}
                    >
                      Runners & Riders Ready!
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        mt: configEntzy.APP_SPACING_MD2X,
                      }}
                    >
                      Entzy helps fill your calendar with activities by finding
                      dates to match event runners (organizers) and event riders
                      (guests), using eventuators.
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        mt: configEntzy.APP_SPACING_MD2X,
                      }}
                    >
                      Eventuators are event launchers designed to trigger
                      activity when everyone is available. Have a play as a
                      runner below with a simulation.
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{
                        mt: configEntzy.APP_SPACING_MD2X,
                      }}
                    >
                      Or if you already have a link, then join in-play activity
                      for real, as a rider.
                    </Typography>
                  </Container>
                </Box>

                <Box className="box-default half-width">
                  <Box
                    className="box-inline right"
                    sx={{
                      width: configEntzy.AVATAR_SIZE_LG,
                    }}
                  >
                    <BigButton
                      bgColor="transparent"
                      icon={showCreate ? iconClose : iconNewRunner}
                      text={showCreate ? "Close" : "Try as Runner"}
                      subText={showJoin ? " " : "Event Organizer"}
                      onClick={handleShowCreate}
                    />
                  </Box>
                </Box>
                <Box className="box-default half-width">
                  <Box
                    className="box-inline left"
                    sx={{
                      width: configEntzy.AVATAR_SIZE_LG,
                    }}
                  >
                    <BigButton
                      bgColor="transparent"
                      icon={showJoin ? iconClose : iconNewRider}
                      text={showJoin ? "Close" : "Join as Rider"}
                      subText={showJoin ? " " : "Event Guest"}
                      onClick={handleShowJoin}
                    />
                  </Box>
                </Box>
                {/* <Box
                  className="box-default"
                  sx={{
                    mt: configEntzy.APP_SPACING_HL,
                  }}
                >
                  <Box
                    className="box-inline"
                    sx={{
                      width: configEntzy.AVATAR_SIZE_LG,
                    }}
                  >
                    <BigButton
                      color="transparent"
                      icon={props.drawerConnect ? iconClose : iconConnect}
                      text={
                        props.drawerConnect ? "Close" : "Connect to Sign In"
                      }
                      onClick={props.drawerConnectToggle}
                    />
                  </Box>
                </Box> */}
                <Box
                  className="box-default"
                  sx={{
                    pt: configEntzy.APP_SPACING_HL,
                    pb: configEntzy.APP_SPACING_XL,
                  }}
                >
                  <WidgetHomeMenu {...props} />
                </Box>
              </Box>
            </Fade>
          </Container>
          <Box className="box-default">{renderCreateForm()}</Box>
          <Box className="box-default" hidden={showCreate}>
            {renderJoinForm()}
          </Box>
          {/* <Box
              className="box-default"
              sx={{
                mt: configEntzy.APP_SPACING_XL2X,
              }}
            >
              <Box className="box-default">
                <Box className="box-inline">
                  <UserAvatar
                    user={props.user}
                    size="md"
                    onClick={
                      user.connected
                        ? () => props.menuSelectById("settings-profile")
                        : null
                    }
                  />
                </Box>
              </Box>
              <Box
                className="box-default"
                sx={{
                  mt: configEntzy.APP_SPACING_LG,
                  mb: configEntzy.APP_SPACING_XL,
                }}
              >
                <Typography variant="h5">
                  When connected
                  <span
                    className="action-pointer"
                    onClick={() => props.drawerConnectToggle(true)}
                  >
                    <span>&nbsp;&nbsp;</span>
                    <FontAwesomeIcon icon={iconConnect} fixedWidth />
                  </span>
                </Typography>
                <Typography variant="h5">your eventuator feed</Typography>
                <Typography variant="h5">will be here</Typography>
              </Box>
              {[1, 2, 3].map((item) => (
                <Box
                  key={item}
                  className="box-default bg-black-t25"
                  sx={{
                    mt: configEntzy.APP_SPACING_MD,
                    p: configEntzy.APP_SPACING_MD2X,
                    border: configEntzy.BORDER_DASH_GSM,
                    borderRadius: configEntzy.BORDER_SIZE_XL,
                  }}
                >
                  <Typography variant="h5">&nbsp;</Typography>
                </Box>
              ))}
            </Box>
            <Box
              className="box-default"
              sx={{
                mt: configEntzy.APP_SPACING_XL2X,
              }}
            >
              <Typography variant="h5">Want to know more?</Typography>
            </Box>
            <Box
              className="box-default"
              sx={{
                mt: configEntzy.APP_SPACING_LG,
                mb: configEntzy.APP_SPACING_HL2X,
                color: blueGrey[600],
              }}
            >
              <Container maxWidth="xs">
                <ActionBoxButton
                  variant="contained"
                  color="bright"
                  bgColor="transparent"
                  size="medium"
                  type="button"
                  onClick={() => props.menuSelectById("about")}
                  text="About Entzy"
                  topIcon={
                    <FontAwesomeIcon
                      icon={iconInfo}
                      className="fa-beat"
                      style={{
                        "--fa-animation-duration": "1.5s",
                        "--fa-beat-scale": 1.1,
                      }}
                      size="3x"
                      fixedWidth
                    />
                  }
                />
              </Container>
            </Box> */}
        </Box>
      )}
      <MainCallbackLoader />
    </Box>
  );
}

export default EventList;
