// react core
import { useContext } from "react";

// theming
import mainTheme from "components/main/MainTheme";
import { ThemeProvider, responsiveFontSizes } from "@mui/material/styles";

// material design
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

// entzy config and components
import configEntzy from "components/config/ConfigEntzy";
import AdminApprovals from "./AdminApprovals";
import { MainContext } from "components/main/MainContext";
import { BigButton } from "components/utils/common/CommonButtons";
import { TextTag } from "components/utils/common/CommonTags";

// fonts and icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserShield as iconAdmin } from "@fortawesome/pro-duotone-svg-icons";
import { faCheckToSlot as iconAdminApprovals } from "@fortawesome/pro-duotone-svg-icons";
// import { faUsers as iconAdminApprovalsUsers } from "@fortawesome/pro-duotone-svg-icons";
import { faBroadcastTower as iconAdminApprovalsSocials } from "@fortawesome/pro-duotone-svg-icons";
import { faCircleUpLeft as iconClose } from "@fortawesome/pro-solid-svg-icons";

function AdminIndex(props) {
  const user = props.user;
  const adminMenu = [
    {
      id: "approvals",
      icon: iconAdminApprovals,
      renderComponent: (props) => <AdminApprovals {...props} />,
      subSections: [
        {
          id: "socials",
          icon: iconAdminApprovalsSocials,
        },
        // {
        //   id: "users",
        //   icon: iconAdminApprovalsUsers,
        // },
      ],
    },
  ];

  const mainContext = useContext(MainContext);

  const handleAdminMenuSelect = async (params) => {
    mainContext.updateViewerTrail({
      controlPage: params.selection,
    });
  };

  return (
    <ThemeProvider theme={responsiveFontSizes(mainTheme)}>
      {user.admin && (
        <Box
          className="box-default"
          sx={{
            mb: configEntzy.APP_SPACING_HL2X,
          }}
        >
          <Box
            className="box-default"
            sx={{
              p: configEntzy.APP_SPACING_LG,
              borderRadius: configEntzy.BORDER_SIZE_XL,
            }}
          >
            <Box className="box-default">
              <Typography
                variant="h2"
                noWrap={true}
                sx={{
                  mt: configEntzy.APP_SPACING_LG,
                }}
              >
                <FontAwesomeIcon icon={iconAdmin} size="2x" />
              </Typography>
              <Typography
                variant="h2"
                noWrap={true}
                sx={{
                  mt: configEntzy.APP_SPACING_LG,
                  color: "orange",
                }}
              >
                Admin
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  mt: configEntzy.APP_SPACING_SM,
                }}
              >
                Control Tower
              </Typography>
            </Box>
            <Box
              className="box-default"
              hidden={
                mainContext.state.viewer.trail.controlPage === null
                  ? false
                  : true
              }
            >
              {adminMenu.map((menu, index) => (
                <Box
                  key={index}
                  className="box-default"
                  sx={{
                    mt: configEntzy.APP_SPACING_LG,
                  }}
                >
                  <BigButton
                    text={menu.id}
                    subText={menu.subSections.map(
                      (subSection) => " (" + subSection.id + ") "
                    )}
                    icon={menu.icon}
                    bgColor="dusk.light"
                    textColor="white"
                    iconColor="black"
                    onClick={() =>
                      handleAdminMenuSelect({ selection: menu.id })
                    }
                  />
                </Box>
              ))}
            </Box>
            {mainContext.state.viewer.trail.controlPage !== null && (
              <Box className="box-default">
                <Box
                  className="box-default text-left action-pointer"
                  sx={{
                    pl: configEntzy.APP_SPACING_SM,
                    pb: configEntzy.APP_SPACING_MD2X,
                  }}
                  onClick={() => handleAdminMenuSelect({ selection: null })}
                >
                  <Typography variant="h6">
                    <TextTag
                      size="md"
                      rounded={true}
                      bgColor="black"
                      text={
                        <span>
                          <span>&nbsp;</span>
                          <FontAwesomeIcon icon={iconClose} />
                          <span>&nbsp;&nbsp;MENU&nbsp;</span>
                        </span>
                      }
                    />
                  </Typography>
                </Box>
                {adminMenu.map((menu, index) => (
                  <Box key={index} className="box-default">
                    {menu.renderComponent({
                      ...props,
                      adminMenu: adminMenu,
                    })}
                  </Box>
                ))}
              </Box>
            )}
          </Box>
        </Box>
      )}
    </ThemeProvider>
  );
}

export default AdminIndex;
